<template>
    <Sheet
        class="pt-[1.75rem] pl-[2rem] pr-[2rem] pb-[1.75rem] md:pt-[28px] md:pl-[17px] md:pb-[28px] md:pr-[17px]  flex flex-col items-left">
        <div v-if="registrationStage > 0" class="flex flex-row justify-between mb-[1rem] md:mb-[15px]">
            <div @click="previousRegistrationStep"
                class="font-inter text-[0.85rem] md:text-[12px] text-[#2894FF] cursor-pointer">
                Назад</div>
            <div class="font-inter text-[0.85rem] md:text-[12px]">{{ registrationStageText }}
            </div>
        </div>
        <div class="font-inter font-medium text-[0.87rem] mb-[1.5rem] md:text-[14px] md:mb-[16px]">
            {{ cardName }}
        </div>
        <Input v-if="registrationStage === 0" type="type4-login" placeholder="Электронная почта"
            class="mb-[0.5rem] md:mb-[8px]" v-model="inputEmail"></Input>
        <div v-else-if="registrationStage === 1" class="flex flex-col mb-[0.5rem] md:mb-[8px]">
            <Input class="md:mb-0 mb-[0.3rem]" type="type4-login" placeholder="Имя" v-model="inputFirstName"></Input>
            <Input type="type4-login" placeholder="Фамилия" v-model="inputLastName"></Input>
        </div>
        <Input v-if="registrationStage === 2" type="type4-login" placeholder="+ 7 (123) 456-78-90"
            class="mb-[0.5rem] md:mb-[8px]" v-model="inputPhone"></Input>
        <div v-else-if="((registrationStage === 3) && (registrationType === 'Employee')) || ((registrationStage === 4) && (registrationType === 'Employer'))"
            class="flex flex-col mb-[0.5rem] md:mb-[8px]">
            <Input class="md:mb-0 mb-[0.3rem]" type="type4-login-pass" placeholder="Пароль" :hidden-default="true"
                v-model="inputPassword"></Input>
            <Input type="type4-login-pass" placeholder="Повторите пароль" v-model="inputPasswordRepeat" :hidden-default="true"></Input>
        </div>
        <div v-else-if="(registrationStage === 3) && (registrationType === 'Employer')"
            class="flex flex-col mb-[0.5rem] md:mb-[8px]">
            <Input class="md:mb-0 mb-[0.3rem]" type="type4-login" placeholder="Название компании"
                v-model="inputCompanyName"></Input>
            <Input type="type4-login" placeholder="Город" v-model="inputCity"></Input>
        </div>
        <div v-if="allert !== null"
            class="font-[Inter] text-[0.65rem] md:text-[10px] text-[#FF0000] w-full text-left mb-[0.4rem] md:mb-[11px]">
            {{ allert }}</div>
        <div v-else class="w-full mb-[1.05rem] md:mb-[21px]"></div>
        <Button
            v-if="(([0, 1, 2, 3].includes(registrationStage)) && (registrationType === 'Employer') || ([0, 1, 2].includes(registrationStage)) && (registrationType === 'Employee'))"
            @click="nextRegistrationStep" type="login-logincard" class="mb-[0.71rem] md:mb-[10px]">Продолжить</Button>
        <Button v-else @click="registration" type="login-logincard"
            class="mb-[0.71rem] md:mb-[10px]">Зарегистрироваться</Button>
        <div v-if="registrationStage === 0" class="flex flex-col">
            <div class="flex flex-row justify-between mb-[1rem] md:mb-[15px]">
                <router-link to="/login"
                    class="font-inter text-[0.85rem] md:text-[12px] text-[#1D1D1D] cursor-pointer">Войти</router-link>
                <router-link :to="swithcTypeButtonLink"
                    class="font-inter text-[0.85rem] md:text-[12px] text-[#2894FF] cursor-pointer">{{
            swithcTypeButtonText
                    }}</router-link>
            </div>
            <div class="font-inter text-[0.81rem] md:text-[12px] text-[#B7B7B7] text-left">
                Продолжая регистрацию, вы принимаете <span class="cursor-pointer custom-underline">политику
                    конфиденциальности</span> и <span class="cursor-pointer custom-underline">правила сервиса</span>
            </div>
        </div>

    </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import Button from './../../components/ui/Button.vue';
import Input from './../../components/ui/Input.vue';

import { defineProps, defineEmits, computed, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { useFetch } from '@/composables/useFetch';

const { anonRequest, authRequest } = useFetch();


const props = defineProps({
    modelValue: {
        type: Object,
        default: () => { },
    },
});

const router = useRouter()

const allert = ref(null)
const nextRegistrationStep = () => {
    console.log('next_registration ste[p]!!!')
    // email
    if ((registrationStage.value === 0)) {
        if (isValidEmail(inputEmail.value)) {
            inputEmail.value = inputEmail.value.trim()
            fetchEmailCheck().then(result => {
                if (result === false) {
                    setStage('+')
                    allert.value = null
                } else {
                    allert.value = 'email уже зарегистрирован.'
                }
            })
            
        } else {
            allert.value = 'Неверно введен адрес. Пожалуйста, попробуйте снова.'
        }
        // имя и фамилия
    } else if (registrationStage.value === 1) {
        if ((isNotEmpty(inputFirstName.value)) && (isNotEmpty(inputLastName.value))) {
            inputFirstName.value = inputFirstName.value.trim()
            inputLastName.value = inputLastName.value.trim()
            setStage('+')
            allert.value = null
        } else {
            allert.value = 'Имя и Фамилия должны быть заполнены.'
        }
        // телефон
    } else if (registrationStage.value === 2) {
        if (isValidPhoneNumber(inputPhone.value)) {
            inputPhone.value = inputPhone.value.trim()
            setStage('+')
            allert.value = null
        } else {
            allert.value = 'Неверно введен телефон. Пожалуйста, попробуйте снова.'
        }
        // город и название компании (работодатель)
    } else if ((registrationStage.value === 3) && (registrationType.value === 'Employer')) {
        if ((isNotEmpty(inputCompanyName.value)) && (isNotEmpty(inputCity.value))) {
            inputCompanyName.value = inputCompanyName.value.trim()
            inputCity.value = inputCity.value.trim()
            setStage('+')
            allert.value = null
        } else {
            allert.value = 'Город и название компании должны быть заполнены.'
        }

    }
}

const previousRegistrationStep = () => {
    setStage('-')
    allert.value = null
}

const registration = () => {
    if (isValidPassword(inputPassword.value)) {
        allert.value = null
        if (isMatchingPasswords(inputPassword.value, inputPasswordRepeat.value)) {
            inputPassword.value = inputPassword.value.trim()
            inputPasswordRepeat.value = inputPasswordRepeat.value.trim()
            fetchSignup().then(result => {
                if (result.status) {
                    console.log(result.status)
                    allert.value = result.data
                } else {
                    router.push('/login?to=tarifs')
                }
            })
        } else {
            allert.value = 'Пароли не совпадают.'
        }
    } else {
        allert.value = 'Пароль должен состоять минимум из 6 символов.'
    }
}

const loading = ref(false);
const error = ref(null);

const fetchSignup = async () => {
    loading.value = true; // Устанавливаем статус загрузки
    error.value = null; // Сбрасываем предыдущую ошибку

    try {
        const url = `/signup/`;
        let responseBody = {
                "type": registrationType.value.toLowerCase(),
                "email": inputEmail.value,
                "first_name": inputFirstName.value,
                "last_name": inputLastName.value,
                "password": inputPassword.value,
                "repeat_password": inputPasswordRepeat.value,
                "phone_number": inputPhone.value
        }
        if (registrationType.value === 'Employer') {
            responseBody.city = inputCity.value
            responseBody.company_name = inputCompanyName.value
        }

        const response = await anonRequest(url, {
            method: 'POST',
            body: responseBody
        });
        return response

    } catch (err) {
        console.error('Запрос завершился ошибкой:', err);
        error.value = err; // Устанавливаем ошибку
        return false
    } finally {
        loading.value = false; // Сбрасываем статус загрузки
    }
};

const fetchEmailCheck = async () => {
        const url = `/email_check/`;
        const responseBody = {
                "email": inputEmail.value,
        }

        const response = await anonRequest(url, {
            method: 'POST',
            body: responseBody
        });
        return response

};


const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}

const isValidPhoneNumber = (phoneNumber) => {
    const trimmedPhoneNumber = phoneNumber.trim()
    const regex = /^(7|\+7|8)\d{10}$/;
    return regex.test(trimmedPhoneNumber);
}

// В текущем варианте проверяет только на кол-во символов
const isValidPassword = (password) => {

    const trimmedPassword = password.trim()
    return trimmedPassword.length > 5
}

const isMatchingPasswords = (password, repeatPassword) => {
    const trimmedPassword = password.trim()
    const trimmedRepeatPassword = repeatPassword.trim()
    console.log(trimmedPassword, trimmedRepeatPassword, trimmedPassword === trimmedRepeatPassword)
    return trimmedPassword === trimmedRepeatPassword
}

const isValidName = (name) => {
    const trimmedName = name.trim();
    return trimmedName.length > 0;
}

const isNotEmpty = (string) => {
    const trimmedString = string.trim();
    return trimmedString.length > 0;
}

const emit = defineEmits(['update:modelValue']);

const inputEmail = ref('')
const inputFirstName = ref('')
const inputLastName = ref('')
const inputPhone = ref('')
const inputPassword = ref('')
const inputPasswordRepeat = ref('')
const inputCompanyName = ref('')
const inputCity = ref('')

const registrationType = computed(() => {
    const registrationTypeQuery = route.query.type;
    if (registrationTypeQuery === 'employee') {
        return 'Employee'
    } else if (registrationTypeQuery === 'employer') {
        return 'Employer'
    } else {
        return 'Employee'
    }
});

const registrationStage = computed(() => {
    const registrationStageQuery = route.query.stage;
    if (!registrationStageQuery) {
        return 0
    } else {
        return Number(registrationStageQuery)
    }
});

const cardName = computed(() => {
    if (registrationType.value === 'Employee') {
        if (registrationStage.value === 0) {
            return 'Регистрация | Соискатель'
        } else if (registrationStage.value === 1) {
            return 'Ваше имя'
        } else if (registrationStage.value === 2) {
            return 'Номер телефона'
        } else if (registrationStage.value === 3) {
            return 'Введите пароль'
        } else {
            return ''
        }

    } else if (registrationType.value === 'Employer') {
        if (registrationStage.value === 0) {
            return 'Регистрация | Работодатель'
        } else if (registrationStage.value === 1) {
            return 'Ваше имя'
        } else if (registrationStage.value === 2) {
            return 'Номер телефона'
        } else if (registrationStage.value === 3) {
            return 'Ваша компания'
        } else if (registrationStage.value === 4) {
            return 'Введите пароль'
        } else {
            return ''
        }
    } else {
        return ''
    }
})

const swithcTypeButtonText = computed(() => {
    if (registrationType.value === 'Employee') {
        return 'Я в поиске сотрудника'
    } else if (registrationType.value === 'Employer') {
        return 'Я в поиске работы'
    } else {
        return ''
    }
})

const swithcTypeButtonLink = computed(() => {
    if (registrationType.value === 'Employee') {
        return '/signup?type=employer'
    } else if (registrationType.value === 'Employer') {
        return '/signup?type=employee'
    } else {
        return ''
    }
})

const registrationStageText = computed(() => {
    let registrationStageTotal = null
    if (registrationType.value === 'Employee') {
        registrationStageTotal = 3
    } else if (registrationType.value === 'Employer') {
        registrationStageTotal = 4
    }
    return `Шаг ${registrationStage.value} из ${registrationStageTotal}`
});



const setStage = (direction) => {
    let registrationStageQuery = route.query.stage;
    if (!registrationStageQuery) {
        registrationStageQuery = 0
    } else {
        registrationStageQuery = Number(registrationStageQuery)
    }
    if (direction === '+') {
        registrationStageQuery += 1
    } else if (direction === '-') {
        registrationStageQuery -= 1
    }
    router.replace({
        query: {
            ...route.query,
            stage: registrationStageQuery
        }
    })
};


const route = useRoute();



</script>

<style scoped></style>