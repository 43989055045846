import {defineStore} from 'pinia';
import {useFetch} from '@/composables/useFetch';

import {jwtDecode} from 'jwt-decode';

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    isAuthenticated: false,
    role: 'employee',
    me: null, // null - если данные не загружены, {id: "", ..., } - если данные загружены
  }),
  actions: {

    getMe(force = false) {
      console.log('get_mE!!!!')
      const fetch = useFetch()
      if (force || (!this.me)) {
        return fetch.authRequest('/me/').then((data) => {
          this.me = data
          this.role = this.me.role
        })
      }


    },
    checkIsAuthenticated() {
      // Логика проверки
      const token = localStorage.getItem("store.auth.token");
      if (!token) {
        this.isAuthenticated = false
      } else {
        const decoded = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000); // текущее время в секундах

        if (decoded.exp < currentTime) {
          this.isAuthenticated = false
          localStorage.removeItem("store.auth.token");
        } else {
          this.isAuthenticated = true
        }

      }
      return this.isAuthenticated
    },

    setRole(newRole) {
      this.role = newRole; // Установка новой роли
    },

    logout() {
      localStorage.removeItem("store.auth.token");
      this.isAuthenticated = false
      window.location.assign("/");

    }
  },
});