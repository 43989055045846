<template>
  <LKLayout>
    <div class="wj-container md:w-[685px] md:min-h-[300px] px-[15px] py-[15px] md:px-[1rem] md:py-[1rem]">
      <div class="flex justify-between mb-[60px]">
        <div class="title">Мои резюме</div>
      </div>
      <Tabs :tabs="tabs" v-model="activeTab">
        <template #tab1>
          <div
            class="md:pr-[1.5rem] md:pl-[1.5rem] pt-[1.81rem] pb-[1.68rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px]">
            <UINoData :data="resumes.length" :loading="!fetched"></UINoData>
            <div class="mb-[0.62rem] md:mb-[20px]" v-for="(item, index) in resumes" :key="index">
              <ResumeCard :publicationTime=item.created_at :resumeName=item.speciality_name
                          :age=item.full_years :price=item.salary_expectations
                          :currencyName=item.currency_display_symbol :experience=item.work_experience
                          :lastJobPlace="(item.last_job_info !== null) ? item.last_job_info.company_name : null"
                          :lastJobTimeStart="(item.last_job_info !== null) ? item.last_job_info.start_date : null"
                          :lastJobTimeEnd="(item.last_job_info !== null) ? item.last_job_info.end_date : null"
                          v-model="isChecked2">
                <template #date-time>
                                    <span
                                        class="text-[#00BD35] text-[0.8rem] md:text-[12px] leading-[0.5rem] md:leading-[12px] font-[Inter] font-normal">{{
                formatDateTimeResumeUpdated(item.updated_at) }}</span>
                                </template>
                                <template #mainInfo>
                                    <div
                                        class="text-[1.0rem] lg:text-[1rem] leading-[0.62rem] font-[Inter] font-regular mb-[11px] mt-[13px]">
                                        {{ item.views }} просмотров</div>
                                    <div @click.stop="router.push(`/employee/lk/feedbacks/invations`)"
                                        class="text-[1.0rem] lg:text-[1rem] leading-[0.62rem] text-[#2894FF] font-[Inter] font-regular mb-[15px] cursor-pointer">
                                        {{ item.invations_count }} приглашений</div>
                                </template>
                                <template #actions>
                                    <div class="flex flex-col md:flex-row mt-3 md:mt-0 gap-2">
                                        <Button @click="withdrawResumeMethod(item.id)" type="login-logincard"
                                            class="!text-[14px] !h-[38px]">Снять с публикации</Button>
                                        <Button @click="updateResumeMethod(item.id)" type="signup"
                                            class="!text-[14px] !h-[38px]">Изменить</Button>
                                    </div>
                                </template>
                            </ResumeCard>
                        </div>
                    </div>

        </template>
        <template #tab2>
          <div
            class="md:pr-[1.5rem] md:pl-[1.5rem] pt-[1.81rem] pb-[1.68rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px]">
            <UINoData :data="resumes.length" :loading="!fetched"></UINoData>
            <div class="mb-[0.62rem] md:mb-[20px]" v-for="(item, index) in resumes" :key="index">
              <ResumeCard :publicationTime=item.created_at :resumeName=item.speciality_name
                          :age=item.full_years :price=item.salary_expectations
                          :currencyName=item.currency_display_symbol :experience=item.work_experience
                          :lastJobPlace="(item.last_job_info !== null) ? item.last_job_info.company_name : null"
                          :lastJobTimeStart="(item.last_job_info !== null) ? item.last_job_info.start_date : null"
                          :lastJobTimeEnd="(item.last_job_info !== null) ? item.last_job_info.end_date : null"
                          v-model="isChecked2" :canPublish="true" :canUpdate="true" :canToArchive="true">
                <template #date-time>
                                    <span
                                        class="text-[#00BD35] text-[0.8rem] md:text-[12px] leading-[0.5rem] md:leading-[12px] font-[Inter] font-normal">{{
                formatDateTimeResumeUpdated(item.updated_at) }}</span>
                                </template>
                                <template #mainInfo>
                                    <div
                                        class="text-[1.0rem] lg:text-[1rem] leading-[0.62rem] font-[Inter] font-regular mb-[11px] mt-[13px]">
                                        {{ item.views }} просмотров</div>
                                    <div @click.stop="router.push(`/employee/lk/feedbacks/invations`)"
                                        class="text-[1.0rem] lg:text-[1rem] leading-[0.62rem] text-[#2894FF] font-[Inter] font-regular mb-[15px] cursor-pointer">
                                        {{ item.invations_count }} приглашений</div>
                                </template>
                                <template #actions>
                                  <div class="flex flex-col md:flex-row mt-3 md:mt-0 gap-2">
                                        <Button @click="publishResumeMethod(item.id)" type="login-logincard"
                                        class="!text-[14px] !h-[38px]">Опубликовать</Button>
                                        <Button @click="updateResumeMethod(item.id)" type="signup"
                                        class="!text-[14px] !h-[38px]">Изменить</Button>
                                      <Button type="signup" class="!text-[14px] !h-[38px]" @click.stop="clone(item.id)">
                                        Дублировать
                                      </Button>
                                        <Button @click="toArchiveResumeMethod(item.id)" type="signup"
                                        class="!text-[14px] !h-[38px] whitespace-nowrap">В архив</Button>
                                    </div>

                </template>
              </ResumeCard>
            </div>
          </div>
        </template>
        <template #tab3>

          <div
            class="md:pr-[1.5rem] md:pl-[1.5rem] pt-[1.81rem] pb-[1.68rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px]">
            <UINoData :data="resumes.length" :loading="!fetched"></UINoData>

            <div class="mb-[0.62rem] md:mb-[20px]" v-for="(item, index) in resumes" :key="index">
              <ResumeCard :publicationTime=item.created_at :resumeName=item.speciality_name
                          :age=item.full_years :price=item.salary_expectations
                          :currencyName=item.currency_display_symbol :experience=item.work_experience
                          :lastJobPlace="(item.last_job_info !== null) ? item.last_job_info.company_name : null"
                          :lastJobTimeStart="(item.last_job_info !== null) ? item.last_job_info.start_date : null"
                          :lastJobTimeEnd="(item.last_job_info !== null) ? item.last_job_info.end_date : null"
                          v-model="isChecked2">
                <template #date-time>
                                    <span
                                        class="text-[#00BD35] text-[0.8rem] md:text-[12px] leading-[0.5rem] md:leading-[12px] font-[Inter] font-normal">{{
                formatDateTimeResumeUpdated(item.updated_at) }}</span>
                                </template>
                                <template #mainInfo>
                                    <div
                                        class="text-[1.0rem] lg:text-[1rem] leading-[0.62rem] font-[Inter] font-regular mb-[11px] mt-[13px]">
                                        {{ item.views }} просмотров</div>
                                    <div @click.stop="router.push(`/employee/lk/feedbacks/invations`)"
                                        class="text-[1.0rem] lg:text-[1rem] leading-[0.62rem] text-[#2894FF] font-[Inter] font-regular mb-[15px] cursor-pointer">
                                        {{ item.invations_count }} приглашений</div>
                                </template>
                                <template #actions>
                                  <div class="flex flex-col md:flex-row mt-3 md:mt-0 gap-2">
                                        <Button @click="toDraftResumeMethod(item.id)" type="login-logincard"
                                        class="!text-[14px] !h-[38px]">В черновик</Button>
                                    </div>
                                    
                                </template>
                            </ResumeCard>
                        </div>
                    </div>
                </template>

      </Tabs>

      <div class="mb-[0.61rem] md:mb-[19px]" v-if="resumes.length < itemsPerPage"></div>
      <div v-if="totalPages > 1" class="flex flex-row justify-start md:mt-[46px]">
        <Pagination v-model="page" :total="totalPages"></Pagination>
      </div>
    </div>
  </LKLayout>

</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import LKLayout from "@/layouts/LKLayout";
import LKCard from "@/components/cards/LKCard"
import {onMounted, reactive, ref, computed, watch, onBeforeMount} from "vue";
import {formatDateTimeResumeUpdated} from '@/composables/utils';
import {useFetch} from "@/composables/useFetch";
import ResumeCard from "@/components/cards/ResumeCard.vue";
import Tabs from "@/components/ui/Tabs";
import Pagination from "@/components/ui/Pagination";
import Button from "@/components/ui/Button";
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import {useAuthStore} from "@/stores/authStore";
import {useToast} from "vue-toast-notification";
import UINoData from "@/components/ui/UINoData";

const fetch = useFetch()
const route = useRoute();
const router = useRouter();
const toast = useToast()

const fetched = ref(false)

const tabs = [
  {name: "tab1", label: "Опубликованные"},
  {name: "tab2", label: "Черновики"},
  {name: "tab3", label: "Архив"},
  // { name: "tab4", label: "На модерации" },
];

const withdrawResumeMethod = (id) => {
  fetch.authRequest(`/resumes/${id}/update`, {
    method: 'PUT',
    body: {
      status: 'DRAFT'
    }
  }).then((data) => {
    page.value = 1
    getResumes()
    toast.success('Перемещено в черновики')
    // fetchResumesData({ user: userId.value, status__in: 'PUBLISHED', limit: itemsPerPage.value });
  })
};

const clone = (id) => {
  console.log('123')
  fetch.authRequest(`/resumes/${id}/clone`, {
    method: 'POST',
  })
    .then(() => {
      toast.success('Создан дубликат резюме')

      getResumes()

    })
}

const toDraftResumeMethod = (id) => {
  console.log(';to draft!!!')
  fetch.authRequest(`/resumes/${id}/update`, {
    method: 'PUT',
    body: {
      status: 'DRAFT'
    }
  }).then((data) => {
    page.value = 1
    toast.success('Перемещено в черновики')
    getResumes()
    // fetchResumesData({ user: userId.value, status__in: 'ARCHIVE', limit: itemsPerPage.value });
  })
};

const publishResumeMethod = (id) => {
  fetch.authRequest(`/resumes/${id}/update`, {
    method: 'PUT',
    body: {
      status: 'PUBLISHED'
    }
  }).then((data) => {
    page.value = 1
    toast.success('Опубликовано')
    getResumes()
    // fetchResumesData({ user: userId.value, status__in: 'DRAFT', limit: itemsPerPage.value });
  })
};

const toArchiveResumeMethod = (id) => {
  fetch.authRequest(`/resumes/${id}/update`, {
    method: 'PUT',
    body: {
      status: 'ARCHIVE'
    }
  }).then((data) => {
    page.value = 1
    toast.success('Перемещено в архив')
    // fetchResumesData({ user: userId.value, status__in: 'DRAFT', limit: itemsPerPage.value });
    getResumes()
  })
};

const updateResumeMethod = (id) => {
  router.push(`/employee/lk/resumes/${id}`)
};

const resumes = ref([]);
const userId = ref(null)
const loading = ref(false);
const error = ref(null);
const itemsPerPage = ref(5)
const page = ref(1)
const totalCount = ref(1)
const authStore = useAuthStore()

const activeTab = ref(0);

const offset = computed(() => {
  return (page.value - 1) * itemsPerPage.value
})

const totalPages = computed(() => {
  return Math.floor((totalCount.value - 1) / itemsPerPage.value) + 1
})


const fetchResumesData = async (queryParams = {}) => {
  console.log('Fetching resumes data...');
  loading.value = true; // Устанавливаем статус загрузки
  error.value = null; // Сбрасываем предыдущую ошибку
  queryParams.limit = itemsPerPage.value
  queryParams.offset = offset.value

  try {
    // Создаем строку запроса вручную
    const queryArray = [];
    for (const key in queryParams) {
      const value = queryParams[key];
      // Проверьте, является ли значение массивом
      if (Array.isArray(value)) {
        // Если массив, объедините значения через запятую
        queryArray.push(`${key}=${value.join(',')}`);
      } else {
        queryArray.push(`${key}=${value}`);
      }
    }
    let queryString = queryArray.join('&');
    queryString
    const url = `/resumes/${queryString ? '?' + queryString : ''}`;

    const response = await fetch.authRequest(url, {
      method: 'GET',
    });

    console.log('Request URL:', response); // Логируем конечный URL

    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      resumes.value = response.results; // Предполагаем, что API возвращает массив данных
      totalCount.value = response.count
      fetched.value = true
      console.log(resumes.value)
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
    loading.value = false; // Сбрасываем статус загрузки
  }
};

const getResumes = () => {
  fetched.value = false
  resumes.value = []
  if (activeTab.value === 0) {
    fetchResumesData({
      user: authStore.me.id,
      status__in: 'PUBLISHED',
    });
  } else if (activeTab.value === 1) {
    fetchResumesData({
      user: authStore.me.id,
      status__in: 'DRAFT',
    });
  } else if (activeTab.value === 2) {
    fetchResumesData({
      user: authStore.me.id,
      status__in: 'ARCHIVE',
    });
  }
}

onBeforeMount(() => {
  getResumes()
})

watch(activeTab, (newValue) => {
  page.value = 1
  getResumes()

});

watch(page, (newValue) => {
  getResumes()

});

</script>

<style scoped>
.wj-container {
  /* Rectangle 1001541 */


  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.title {
  /* Создание вакансии */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #4A4A4A;


}
</style>