<template>
    <!-- Тут высота инпута 42px + высота уведомления 22px + "отступ" - решил сделать 20px -->
    <div >
        <input
          :value="modelValue"
          @input="inputHandler"
          :valid="(error === null)"
          :id="props.id"
          class="form-input w-full"
          :placeholder="placeholder"

        />
        <div v-if="error" class="error_message">{{error}}</div>
    </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, computed, onMounted, watch} from 'vue'

const props = defineProps({
    modelValue: {
        type: String,
        required: true
    },
    id: {
        type: String,
        default: ''
    },
    class: {
      type: String,
      default: ''
    },
    rules: {
        type: Array,
        default: () => [] 
    },
    placeholder: {
      default: null,
    }
})

onMounted(() => {
  validate(props.modelValue);
})

watch(() => props.modelValue, (value) => {
  validate(value);
})

const emit = defineEmits(['update:modelValue', 'valid'])

const error = ref(null)

// const standardClass = "form-input w-full ";
// const computedClasses = computed(() => {
//     return standardClass + props.class;
// });

const inputHandler = (event) => {
    emit('update:modelValue', event.target.value)
    validate(event.target.value);
}

const validate = (val) => {
    let isValid = true;
    for (let i = 0; i < props.rules.length; i++) {
        
        const res = props.rules[i](val)
        if (res !== null) {
            error.value = res
            isValid = false;
            break;
        } else {
            error.value = null
        }
    }
    // console.log('error', error.value)

    emit('valid', isValid);
}

onMounted(() => {
// console.log('on mounted!!!!')
validate(props.modelValue)
})
</script>

<style scoped>
.form-input {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 26px;
    gap: 10px;

    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 15px;

    height: 42px;
}




.form-input[valid="false"] {
  outline: 1px solid #DB4545;
}

.form-input[valid="true"] {
  outline: 1px solid #ECECEC
}

.form-input:focus {
  outline: 1px solid #2894FF;
}


.error_message {

font-family: 'Inter';
font-style: normal;
font-size: 14px;
margin-top: 10px;
margin-bottom: 0px;
line-height: 22px;
letter-spacing: 0.04em;

color: #DB4545;
}
</style>