<template>
    <LKLayout>
  
    <div class="wj-container md:w-[645px] md:min-h-[300px] px-[15px] py-[15px] md:px-[1rem] md:py-[1rem]">
        <div class="flex justify-between mb-[60px]">
            <div class="flex flex-col items-center">
                <div class="font-[Inter] font-bold text-[24px] text-[#4A4A4A] ">
                    Тарифная сетка
                </div>
            <div class="font-[Inter] font-bold text-[18px]  text-[#8A8A8A] mb-10">
            Стоимость указана за календарный месяц
            </div>
            <div class="flex flex-col  gap-[10px] w-full md:w-[600px]">
                <TarifCard v-for="plan in billingStore.plans.items" :key="plan.id" :name="plan.name" :active="($authStore.me.user_subscription && $authStore.me.user_subscription.rate === plan.id) || false"
                   :id="plan.id" :byeButton="true" :dateEnd="($authStore.me.user_subscription && $authStore.me.user_subscription.date_end) || null"
                   :price="plan.price_per_month" :vacanciesForPublication="plan.vacancies_for_publication" 
                   :canSeeContacts="plan.can_see_contacts" :canSendResponses="plan.can_send_responses"
                ></TarifCard>
            </div>
        </div>
    </div>
  
      </div>
    </LKLayout>
  
  </template>
  
  <script setup>
  import DefaultLayout from "@/layouts/DefaultLayout";
  import LKLayout from "@/layouts/LKLayout";
  import {onMounted, reactive, ref, computed, watch, onBeforeMount} from "vue";
  import UIRadioButton from "@/components/ui/UIRadioButton";
  import UISelect from "@/components/ui/UISelect";
  import {useFetch} from "@/composables/useFetch";
  import Button from "@/components/ui/Button";
  import UITextArea from "@/components/ui/UITextArea";
  import UIInput from "@/components/ui/UIInput";
  import Checkbox from "@/components/ui/Checkbox";
  import UIDatePicker from "@/components/ui/UIDatePicker";
  import {useRouter} from "vue-router";
  import {useRoute} from "vue-router";
  import {useAuthStore} from "@/stores/authStore";
  import {useToast} from "vue-toast-notification";
  import UIFileUploader from "@/components/ui/UIFileUploader";
  import {useBillingStore} from "@/stores/billingStore";
  import TarifCard from "@/components/cards/TarifCard";

  const billingStore = useBillingStore();
  const fetch = useFetch()
  const route = useRoute();
  const router = useRouter();
  const authStore = useAuthStore()
  const toast = useToast()
  

  onBeforeMount(() => {
  billingStore.getPlans()
})
  
  </script>
  
  <style scoped>
  .wj-container {
    /* Rectangle 1001541 */
  
  
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
  
  .form-input {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 26px;
    gap: 10px;
  
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 15px;
  
    height: 42px;
  
  
  }
  
  .form-label {
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #4A4A4A;
    margin-bottom: 20px;
  }
  
  
  .form-sublabel {
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  
    leading-trim: both;
    text-edge: cap;
    letter-spacing: 0.04em;
  
    color: #888888;
  
    margin-bottom: 20px;
  
  
  }
  
  .title {
    /* Создание вакансии */
  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */
    text-align: center;
  
    color: #4A4A4A;
  
  
  }
  </style>