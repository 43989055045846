<template>
    <div class="subscription-card px-[24px] py-[20px]" :class="{ 'active-card': props.active }">
        <span class="mr-[1rem]">Название тарифа:</span><span class="subscription-card-name">{{ tarifName }}</span>

        <div v-if="props.active" class="subscription-card-feature pt-[20px]">
            <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 1L6 12L1 7" stroke="#888888" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <div>Активно</div>
        </div>
        <div v-else class="subscription-card-feature mt-[20px] pt-[20px]">
            <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L17 12" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1 12L17 1" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div>Неактивно</div>
        </div>
        <div class="mt-[20px] subscription-card-feature">
            Дата оформления подписки: {{ formatDateTimeToDate(dateStart) }}
        </div>
        <div class="mt-[20px] subscription-card-feature">
            Дата окончания подписки: {{ dateEnd }}
        </div>
    </div>



    <!-- <div class="mt-[30px]"></div>
    </div> -->
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import Button from "@/components/ui/Button";
import { useAuthStore } from "@/stores/authStore";
import {formatDateTimeToDate} from "@/composables/utils.js"

const authStore = useAuthStore()

const router = useRouter();
const route = useRoute();

const props = defineProps({
    id: {
        default: null
    },
    tarifName: {
        default: 'Тариф'
    },
    active: {
        default: false
    },
    dateEnd: {
        default: null
    },
    dateStart: {
        default: null
    }

})

</script>

<style scoped>
.subscription-card {
    background: #FFFFFF;
    border: 2px solid #ECECEC;
    border-radius: 15px;
    cursor: pointer;
    width: full;

}

.subscription-card[active="true"] {
    border: 2px solid #2894FF;

}

.active-card {
    background-color: #e0f7e0;
    /* Зеленый фон */
    border: 2px solid #4caf50;
    /* Более жирный зеленый контур */
}

.subscription-card:hover {
    border: 2px solid #76b7f8;

}

.subscription-card-name {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    color: #2894FF;


}

.subscription-card-description {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    leading-trim: both;
    text-edge: cap;
    letter-spacing: 0.04em;

    color: #888888;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;


    box-sizing: border-box;


    border-bottom: 1px solid #ECECEC;


}


.subscription-card-price {
    /* 4 444 ₽ */


    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    color: #4A4A4A;


}

.subscription-card-feature {
    /* Преимущество 2 */

    display: flex;
    flex-direction: row;
    gap: 18px;
    /* Добавлено свойство gap */
    align-items: center;
    /* Добавлено свойство align-items */
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* идентична высоте блока */

    color: #000000;
}
</style>