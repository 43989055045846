<template>
  <LKLayout>
    <div class="wj-container md:w-[645px] md:min-h-[300px] px-[15px] py-[15px] md:px-[1rem] md:py-[1rem]">
      <div class="flex justify-between mb-[60px]">
        <div class="title">Мои вакансии</div>
      </div>
      <Tabs :tabs="tabs" v-model="activeTab">
        <template #tab1>
          <div
            class="md:pr-[1.5rem] md:pl-[1.5rem] pt-[1.81rem] pb-[1.68rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px]">
            <UINoData :data="vacancies.length" :loading="!fetched"></UINoData>

            <div class="mb-[0.62rem]" v-for="(item, index) in vacancies" :key="index">
              <VacancyCardListitem :vacancyId=item.id :vacancyName=item.name :priceFrom=item.price_from
                :experience=item.experience :priceTo=item.price_to :currencyName=item.currency_display_name
                :city=item.city :company=item.employer_name :publicationTime=item.created_at
                :disableResponseButton="true" v-model="isChecked" :noDate="true">
                <template #date-time>
                  <div class="font-inter font-normal text-[0.81rem] md:text-[12px] text-[#00BD35] mb-[16px]">
                    {{ formatDateTimeResumeUpdated(item.updated_at) }}
                  </div>
                </template>
                <template #mainInfo>
                  <div
                    class="font-inter font-semibold md:font-bold text-[1.12rem] md:text-[16px] text-black md:text-[#2894FF]">
                    {{ item.name }}</div>
                  <div
                    class="text-[0.75rem] lg:text-[1rem] leading-[0.62rem] font-[Inter] font-regular mb-[11px] mt-[13px]">
                    {{ item.views }} просмотров</div>
                  <div @click.stop="router.push(`/employer/lk/feedbacks/responses`)"
                    class="text-[0.75rem] lg:text-[1rem] leading-[0.62rem] text-[#2894FF] font-[Inter] font-regular mb-[15px]">
                    {{ item.responses_count }} откликов</div>
                </template>
                <template #actions>
                  <div class="flex flex-col md:flex-row mt-3 md:mt-0 gap-3">
                    <Button type="login-logincard" class="!text-[14px] !h-[38px]" @click.stop="draft(item.id)">
                      Снять с публикации
                    </Button>
                    <Button type="signup" class="!text-[14px] !h-[38px]"
                      @click.stop="$router.push(`/employer/lk/vacancies/${item.id}`)">
                      Изменить
                    </Button>
                    <Button type="signup" class="!text-[14px] !h-[38px] whitespace-nowrap"
                      @click.stop="archive(item.id)">
                      В архив
                    </Button>
                  </div>
                </template>

              </VacancyCardListitem>
            </div>
          </div>

        </template>
        <template #tab2>
          <div
            class="md:pr-[1.5rem] md:pl-[1.5rem] pt-[1.81rem] pb-[1.68rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px]">
            <UINoData :data="vacancies.length" :loading="!fetched"></UINoData>
            <div class="mb-[0.62rem]" v-for="item in vacancies" :key="item.id">
              <VacancyCardListitem :vacancyId="item.id" :vacancyName=item.name :priceFrom=item.price_from
                :experience=item.experience :priceTo=item.price_to :currencyName=item.currency_display_name
                :city=item.city :company=item.employer_name :publicationTime=item.created_at v-model="isChecked"
                :disableResponseButton="true">
                <template #date-time>
                  <div class="font-inter font-normal text-[12px] text-[#00BD35] mb-[16px]">
                    {{ formatDateTimeResumeUpdated(item.updated_at) }}
                  </div>
                </template>
                <template #mainInfo>
                  <div
                    class="font-inter font-semibold md:font-bold text-[1.12rem] md:text-[16px] text-black md:text-[#2894FF]">
                    {{ item.name }}</div>
                  <div
                    class="text-[0.75rem] lg:text-[1rem] leading-[0.62rem] font-[Inter] font-regular mb-[11px] mt-[13px]">
                    {{ item.views }} просмотров</div>
                  <div @click.stop="router.push(`/employer/lk/feedbacks/responses`)"
                    class="text-[0.75rem] lg:text-[1rem] leading-[0.62rem] text-[#2894FF] font-[Inter] font-regular mb-[15px]">
                    {{ item.responses_count }} откликов</div>
                </template>
                <template #actions>
                  <div class="flex flex-col md:flex-row mt-3 md:mt-0 gap-3">
                    <Button type="login-logincard" class="!text-[14px] !h-[38px]" @click.stop="publish(item.id)">
                      Опубликовать
                    </Button>
                    <Button type="signup" class="!text-[14px] !h-[38px]"
                      @click.stop="$router.push(`/employer/lk/vacancies/${item.id}`)">
                      Изменить
                    </Button>
                    <Button type="signup" class="!text-[14px] !h-[38px]" @click.stop="clone(item.id)">
                      Дублировать
                    </Button>
                    <Button type="signup" class="!text-[14px] !h-[38px] whitespace-nowrap"
                      @click.stop="archive(item.id)">
                      В архив
                    </Button>
                  </div>

                </template>
              </VacancyCardListitem>
            </div>
          </div>
        </template>
        <template #tab3>
          <div
            class="md:pr-[1.5rem] md:pl-[1.5rem] pt-[1.81rem] pb-[1.68rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px]">
            <UINoData :data="vacancies.length" :loading="!fetched"></UINoData>
            <div class="mb-[0.62rem]" v-for="(item, index) in vacancies" :key="index">
              <VacancyCardListitem :vacancyId=item.id :vacancyName=item.name :priceFrom=item.price_from
                :experience=item.experience :priceTo=item.price_to :currencyName=item.currency_display_name
                :city=item.city :company=item.employer_name :publicationTime=item.created_at v-model="isChecked"
                :disable-response-button="true">
                <template #date-time>
                  <div class="font-inter font-normal text-[12px] text-[#00BD35] mb-[16px]">
                    {{ formatDateTimeResumeUpdated(item.updated_at) }}
                  </div>
                </template>
                <template #mainInfo>
                  <div
                    class="font-inter font-semibold md:font-bold text-[1.12rem] md:text-[16px] text-black md:text-[#2894FF]">
                    {{ item.name }}</div>
                  <div
                    class="text-[0.75rem] lg:text-[1rem] leading-[0.62rem] font-[Inter] font-regular mb-[11px] mt-[13px]">
                    {{ item.views }} просмотров</div>
                  <div @click.stop="router.push(`/employer/lk/feedbacks/responses`)"
                    class="text-[0.75rem] lg:text-[1rem] leading-[0.62rem] text-[#2894FF] font-[Inter] font-regular mb-[15px]">
                    {{ item.responses_count }} откликов</div>
                </template>
                <template #actions>
                  <div class="flex flex-col md:flex-row mt-3 md:mt-0 gap-3">
                    <Button type="login-logincard" class="!text-[14px] !h-[38px]" @click.stop="draft(item.id)">
                      Восстановить
                    </Button>
                    <Button type="signup" class="!text-[14px] !h-[38px]"
                      @click.stop="$router.push(`/employer/lk/vacancies/${item.id}`)">
                      Изменить
                    </Button>

                  </div>

                </template>
              </VacancyCardListitem>
            </div>
          </div>
        </template>
      </Tabs>

      <div class="mb-[0.61rem] md:mb-[19px]" v-if="vacancies.length < itemsPerPage"></div>
      <div v-if="totalPages > 1" class="flex flex-row justify-start md:mt-[46px]">
        <Pagination v-model="page" :total="totalPages"></Pagination>
      </div>
    </div>
  </LKLayout>

</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import LKLayout from "@/layouts/LKLayout";
import { onMounted, reactive, ref, computed, watch, onBeforeMount } from "vue";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UISelect from "@/components/ui/UISelect";
import { useFetch } from "@/composables/useFetch";
import ResumeCard from "@/components/cards/ResumeCard.vue";
import Button from "@/components/ui/Button";
import UITextArea from "@/components/ui/UITextArea";
import UIInput from "@/components/ui/UIInput";
import Checkbox from "@/components/ui/Checkbox";
import UIDatePicker from "@/components/ui/UIDatePicker";
import Tabs from "@/components/ui/Tabs";
import Pagination from "@/components/ui/Pagination";
import VacancyCardListitem from "@/components/cards/VacancyCardListitem"
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { useVacanciesStore } from "@/stores/vacanciesStore";
import { useToast } from "vue-toast-notification";
import { formatDateTimeResumeUpdated } from '@/composables/utils';
import UINoData from "@/components/ui/UINoData";

const fetch = useFetch()

const fetched = ref(false)
const authStore = useAuthStore()
const vacanciesStore = useVacanciesStore()
const router = useRouter()
const route = useRoute()
const toast = useToast()

const publish = (id) => {
  if (!authStore.me.user_subscription) {
      toast.error('Исчерапаны лимиты на публикацию или тариф не подключен. Купите тариф.',
                    {duration: 6000}
                )
      router.push('/employer/lk/tarif')
      return
    }
  if (authStore.me.vacancies_count >= authStore.me.user_subscription.vacancies_count) {
    toast.error('Исчерапаны лимиты на публикацию или тариф не подключен. Купите тариф.',
      { duration: 6000 }
    )
    router.push('/employer/lk/tarif')
    authStore.getMe(true)
    return
  }
    vacanciesStore.setStatus(id, 'PUBLISHED').then(() => {
      toast.success('Опубликовано')
      authStore.getMe(true)
      toFirstPageIfNeed()
    })
  }

  const draft = (id) => {
    console.log('123')
    vacanciesStore.setStatus(id, 'DRAFT').then(() => {
      toast.success('Снято с публикации')
      authStore.getMe(true)
      toFirstPageIfNeed()

    })
  }

  const archive = (id) => {
    console.log('123')
    vacanciesStore.setStatus(id, 'ARCHIVE').then(() => {
      toast.success('Снято с публикации')
      authStore.getMe(true)
      toFirstPageIfNeed()

    })
  }

  const clone = (id) => {
    console.log('123')
    vacanciesStore.clone(id, 'DRAFT').then(() => {
      toast.success('Создан дубликат вакансии')
      authStore.getMe(true)
      getVacancies()

    })
  }

  // Если мы находимся не на первой странице и что то сделали с публикацией, и их на странице осталось 0, то переходим на первую страницу
  const toFirstPageIfNeed = () => {
    // сделать более продвинуто
    page.value = 1
    getVacancies()

  }

  const tabs = [
    { name: "tab1", label: "Опубликованные" },
    { name: "tab2", label: "Неопубликованные" },
    { name: "tab3", label: "Архив" }
  ];

  const vacancies = ref([]);
  const employerProfileId = ref(null)
  const loading = ref(false);
  const error = ref(null);
  const itemsPerPage = ref(5)
  const page = ref(1)
  const totalCount = ref(1)

  const activeTab = ref(0);

  const offset = computed(() => {
    return (page.value - 1) * itemsPerPage.value
  })

  const totalPages = computed(() => {
    return Math.floor((totalCount.value - 1) / itemsPerPage.value) + 1
  })


  const fetchVacanciesData = async (queryParams = {}) => {
    console.log('Fetching Vacancies data...');
    loading.value = true; // Устанавливаем статус загрузки
    error.value = null; // Сбрасываем предыдущую ошибку
    queryParams.limit = itemsPerPage.value
    queryParams.offset = offset.value

    try {
      // Создаем строку запроса вручную
      const queryArray = [];
      for (const key in queryParams) {
        const value = queryParams[key];
        // Проверьте, является ли значение массивом
        if (Array.isArray(value)) {
          // Если массив, объедините значения через запятую
          queryArray.push(`${key}=${value.join(',')}`);
        } else {
          queryArray.push(`${key}=${value}`);
        }
      }
      let queryString = queryArray.join('&');
      queryString
      const url = `/vacancies/${queryString ? '?' + queryString : ''}`;


      const response = await fetch.authRequest(url, {
        method: 'GET',
      });

      console.log('Request URL:', response); // Логируем конечный URL

      // Если запрос успешен, сохраняем данные
      if (response && !response.error) {
        vacancies.value = response.results; // Предполагаем, что API возвращает массив данных
        totalCount.value = response.count

        fetched.value = true
      } else {
        // Если есть ошибка, устанавливаем ошибку
        error.value = response;
      }
    } catch (err) {
      console.error('Запрос завершился ошибкой:', err);
      error.value = err; // Устанавливаем ошибку
    } finally {
      loading.value = false; // Сбрасываем статус загрузки
    }
  };

  onBeforeMount(() => {

    employerProfileId.value = authStore.me.employer_profile_id
    getVacancies()
    // fetchVacanciesData({employer: employerProfileId.value, status__in: 'PUBLISHED'});

  })


  const getVacancies = () => {
    vacancies.value = []
    fetched.value = false

    if (activeTab.value === 0) {
      fetchVacanciesData({
        employer: employerProfileId.value,
        status__in: 'PUBLISHED',
      });
    } else if (activeTab.value === 1) {
      fetchVacanciesData({
        employer: employerProfileId.value,
        status__in: 'DRAFT',
      });
    } else if (activeTab.value === 2) {
      fetchVacanciesData({
        employer: employerProfileId.value,
        status__in: 'ARCHIVE',
      });
    }
  }

  watch(activeTab, (newValue) => {
    page.value = 1
    getVacancies()
  });

  watch(page, (newValue) => {
    getVacancies()
    // if (activeTab.value === 0) {
    //   fetchVacanciesData({
    //     employer: employerProfileId.value,
    //     status__in: 'PUBLISHED',
    //   });
    // } else if (activeTab.value === 1) {
    //   fetchVacanciesData({
    //     employer: employerProfileId.value,
    //     status__in: 'DRAFT',
    //   });
    // } else if (activeTab.value === 2) {
    //   fetchVacanciesData({
    //     employer: employerProfileId.value,
    //     status__in: 'ARCHIVE',
    //   });
    // }
  });

</script>

<style scoped>
.wj-container {
  /* Rectangle 1001541 */


  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.title {
  /* Создание вакансии */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #4A4A4A;


}
</style>