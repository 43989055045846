<template>
    <Header @toggleScroll="handleToggleScroll"></Header>
    <div class="relative">
        <div class="md:pt-[80px] pt-[5.71rem]">
            <img v-if="(registrationType === 'Employee') && !isMobile" :src="EmployeRegistrationBackground"
                alt="EmployeRegistrationBackground" class="!w-[100vw] !h-[100vh] object-cover" />
            <img v-else-if="(registrationType === 'Employer') && !isMobile" :src="EmployerRegistrationBackground"
                alt="EmployeRegistrationBackground" class="!w-[100vw] !h-[100vh] object-cover" />
        </div>



        <div class="md:absolute top-[30vh] md:left-[31vw] lg:left-[32vw] xl:left-[35.5vw] 2xl:left-[36.5vw] 3xl:left-[37.5vw] 4xl:left-[38.5vw] 5xl:left-[40vw] flex flex-col pr-[1.71rem] pl-[1.71rem]">
            <div class="flex flex-row items-center justify-center">
                <div class="left-0 w-full md:w-[314px]">
                    <div class="mb-[1rem] md:mb-[13px]">
                        <RegistrationCard></RegistrationCard>
                    </div>
                    <div class="mb-[6rem] md:mb-[84px]">
                        <GetSupportCard v-model="showPopup"></GetSupportCard>
                    </div>

                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>


</template>

<script setup>
import Header from './../components/ui/Header.vue'
import { ref, reactive, watch, onUnmounted, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import Footer from './../components/ui/Footer.vue'
import GetSupportCard from './../components/cards/GetSupportCard.vue'
import RegistrationCard from './../components/cards/RegistrationCard.vue'
import EmployeRegistrationBackground from '@/assets/images/signup_background_employee.png';
import EmployerRegistrationBackground from '@/assets/images/signup_background_employer.png';


const route = useRoute();

const registrationType = computed(() => {
    const registrationTypeQuery = route.query.type;
    if (registrationTypeQuery === 'employee') {
        return 'Employee'
    } else if (registrationTypeQuery === 'employer') {
        return 'Employer'
    } else {
        return 'Employee'
    }
});

const registrationStage = computed(() => {
    const registrationStageQuery = route.query.stage;
    if (registrationStageQuery === null) {
        return 0
    } else {
        return registrationStageQuery
    }
});

const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});

const showPopup = ref(false);

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});


</script>

<style></style>