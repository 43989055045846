<template>
  <div class="custom-select flex items-center select-none" @click="toggleDropdown" >
    <div class="select-trigger flex items-center " :class="triggerClass" :valid="(error === null)">
      <div class="flex pl-[26px] pr-[13px] items-center overflow-hidden" style="white-space: nowrap">
        {{ selectedTitle}}
      </div>
      <div class="mr-[26px]">
        <slot name="append-inner">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 7.5L9.5 12.5L4.5 7.5" stroke="#1E1E1E" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </slot>
      </div>

    </div>
    <div  v-if="isOpen" class="dropdown-bg"></div>
    <div v-if="isOpen" class="dropdown "  >
      <div class="px-3 py-3" >
        <UIInput @focus.prevent.stop @click.stop.prevent placeholder="Поиск" v-model="query"> </UIInput>
      </div>
      <div class="max-h-[50vh] overflow-auto">
        <div
          v-for="option in filteredOptions"
          :key="option"
          class="dropdown-item"
          @click.stop="selectOption(option.value)"
        >
          {{ option.title }}
        </div>
      </div>

    </div>
  </div>
  <div v-if="error" class="error_message">{{error}}</div>
</template>

<script setup>
import {ref, defineProps, defineEmits, onMounted, watch, computed} from 'vue';
import UIInput from "@/components/ui/UIInput";

const query = ref(null)

const filteredOptions = computed(() => {
  if (!query.value) {
    return props.options;
  } else {
    const lowerCaseQuery = query.value.toLowerCase();
    return props.options.filter(item =>
      item.title.toLowerCase().includes(lowerCaseQuery)
    );
  }
});


const props = defineProps({
  options: {
    default: () => ([{
      title: 'Вариант 1',
      value: 1,
    },{
      title: 'Вариант 2',
      value: 2,
    },{
      title: 'Вариант 3',
      value: 3,
    }])
  },
  modelValue: {
    default: null,
  },
  rules: {
        type: Array,
        default: () => [] 
  },
  triggerClass: {
    default: null,
  },
  noSearch: {
    default: false
  }

})

onMounted(() => {
  validate(props.modelValue);
})

watch(() => props.modelValue, (value) => {
  validate(value);
})

const emit = defineEmits([
  'update:modelValue', 'valid'
])

const error = ref(null)


const validate = (val) => {
    let isValid = true;
    for (let i = 0; i < props.rules.length; i++) {
        const res = props.rules[i](val)
        if (res !== null) {
            error.value = res
            isValid = false;
            break;
        } else {
            error.value = null
        }
    }
    emit('valid', isValid);
}

const selectedOption = ref(null);

onMounted(() => {
  selectedOption.value = props.modelValue
  validate(props.modelValue)
})

watch(() => props.modelValue, () => {
  selectedOption.value = props.modelValue
  validate(props.modelValue);
})

const selectedTitle = computed(() => {
  for (const option of props.options) {
    if (option.value === selectedOption.value) {
      return option.title
    }
  }
  return '---'
})

const isOpen = ref(false);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const selectOption = (option) => {
  selectedOption.value = option;
  emit('update:modelValue', selectedOption.value)
  validate(selectedOption.value)
  isOpen.value = false;
  query.value = null
};

watch(() => isOpen.value, (isOpen) => {
  const element = document.getElementById('page')
  element.style.overflow = isOpen ? 'hidden' : 'auto'
})


</script>

<style>
.custom-select {
  position: relative;


}

.select-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 350px;
  height: 42px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  /*padding: 8px;*/
  background-color: white;

  background: #FFFFFF;
  border: 2px solid #ECECEC;
  border-radius: 15px;




}

.dropdown-bg {
  /*background-color: #3EC9E6;*/
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  /*right: 0;*/
  background: white;
  border: 1px solid #ececec;
  border-radius: 15px;
  overflow: hidden;
  z-index: 11;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  width: fit-content;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #2894FF;
  color: white;
}

.select-trigger[valid="false"] {
    border: 1px solid #DB4545;
}

.select-trigger[valid="true"] {
    border: 1px solid #ECECEC
}



.error_message {

font-family: 'Inter';
font-style: normal;
font-size: 14px;
margin-top: 10px;
margin-bottom: 0px;
line-height: 22px;
letter-spacing: 0.04em;

color: #DB4545;
}
</style>