

// export const formatPrice = (price) => {
//     if (!price) {
//         return ''
//     }
//     else if (price < 1000) {
//         return price.toString();
//     } else {
//         const thousands = Math.floor(price / 1000); // Округление вниз
//         return `${thousands} тыс.`;
//     }
// }
export const formatPrice = (price) => {
    const thousands = Math.floor((price/1000), 1000)
    let thousandsRemainder = price % 1000
    const thousandsRemainderLength = String(thousandsRemainder).length
    if (thousandsRemainderLength === 1) {
        thousandsRemainder = `00${thousandsRemainder}`
    } else if (thousandsRemainderLength === 2) {
        thousandsRemainder = `0${thousandsRemainder}`
    }
    if (thousands > 0) {
        return `${thousands} ${thousandsRemainder}`
    } else {
        return`${thousandsRemainder}`
    }
}

export const formatDateTimeToDate = (dateTimeString) => {
    if (!dateTimeString || typeof dateTimeString !== 'string') {
        throw new Error("Invalid date time string");
    }

    const dateObj = new Date(dateTimeString);
    
    // Проверяем, корректно ли была создана дата
    if (isNaN(dateObj.getTime())) {
        throw new Error("Invalid date format");
    }

    // Форматируем дату в YYYY-MM-DD
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const day = String(dateObj.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

// const monthNamedeviation = (monthName) => {
//     if (monthName == 'январь') {
//         return 'января'
//     }
//     else if (monthName == 'февраль') {
//         return 'февраля'
//     }
//     else if (monthName == 'март') {
//         return 'марта'
//     }
//     else if (monthName == 'апрель') {
//         return 'апреля'
//     }
//     else if (monthName == 'май') {
//         return 'мая'
//     }
//     else if (monthName == 'июнь') {
//         return 'июня'
//     }
//     else if (monthName == 'июль') {
//         return 'июля'
//     }
//     else if (monthName == 'август') {
//         return 'августа'
//     }
//     else if (monthName == 'сентябрь') {
//         return 'сентября'
//     }
//     else if (monthName == 'октябрь') {
//         return 'октября'
//     }
//     else if (monthName == 'ноябрь') {
//         return 'ноября'
//     }
//     else if (monthName == 'декабрь') {
//         return 'декабря'
//     }
// }

export const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const now = new Date();

    const isToday = date.toDateString() === now.toDateString();
    const currentYear = now.getFullYear();
    const options = { hour: '2-digit', minute: '2-digit', hour12: false };

    let formattedTime = date.toLocaleTimeString('ru-RU', options);

    if (isToday) {
        return `Сегодня в ${formattedTime}`;
    } else if (date.getFullYear() === currentYear) {
        const day = date.getDate();
        const month = date.toLocaleString('ru-RU', { month: 'long' });
        return `${day} ${month} в ${formattedTime}`;
    } else {
        const day = date.getDate();
        const month = date.toLocaleString('ru-RU', { month: 'long' });
        const year = date.getFullYear();
        return `${day} ${month} ${year} г. в ${formattedTime}`;
    }
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${toUpperCaseFirstLetter(date.toLocaleString('ru-RU', { month: 'long' }))} ${date.getFullYear()}`
    
}

export const  toLowerCaseFirstLetter = (str) => {
    if (str.length === 0) return str;
    return str.charAt(0).toLowerCase() + str.slice(1);
}

export const  toUpperCaseFirstLetter = (str) => {
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const formatDateTimeResumeCreated = (dateTimeString) => {
    return `Создано ${toLowerCaseFirstLetter(formatDateTime(dateTimeString))}`
}

export const formatDateTimeResumeUpdated = (dateTimeString) => {
    return `Изменено ${toLowerCaseFirstLetter(formatDateTime(dateTimeString))}`
}

export const formatDateTimeVacancyCreated = (dateTimeString) => {
    return `Размещено ${toLowerCaseFirstLetter(formatDateTime(dateTimeString))}`
}

export const formatAge = (ageNumber) => {
    if (ageNumber === null) {
        return '-'
    } else if (['1', '21', '31', '41', '51', '61', '71', '81', '91'].includes(String(ageNumber))) {
        return `${ageNumber} год`
    } else if (['2', '3', '4', '22', '23', '24', '32', '33', '34', '42', '43','44', '52', '53', '54', '62', '63', '64', '72', '73', '74', '82', '83', '84', '92', '93', '94'].includes(String(ageNumber) )) {
        return `${ageNumber} года`
    } else {
        return `${ageNumber} лет`
    } 
    
}

export const formatexperienceForVacancy = (experienceString) => {
    if (experienceString === 'no_experience') {
        return 'Опыт не требуется'
    }else if (experienceString === 'months_3_12') {
        return 'Опыт от 1 месяца до 1 года'
    } else if (experienceString === 'years_1_3') {
        return 'Опыт от 1 до 3 лет'
    }  else if (experienceString === 'years_3_6') {
        return 'Опыт от 3 до 6 лет'
    } else if (experienceString === 'years_more_6') {
        return 'Опыт более 6 лет'
    }
}

export const humanizeEmploymentType = (employmentTypeString) => {
    if (employmentTypeString === 'full_day') {
        return 'Полная занятость'
    } else if (employmentTypeString === 'not_full_day') {
        return 'Неполная занятость'
    }  else if (employmentTypeString === 'project') {
        return 'Проектная работа'
    } else if (employmentTypeString === 'internship') {
        return 'Стажировка'
    } else if (employmentTypeString === 'volunteering') {
        return 'Волонтерство'
    }
}

export const humanizeEmploymentShedule = (employmentSheduleString) => {
    if (employmentSheduleString === 'full_day') {
        return 'полный день'
    } else if (employmentSheduleString === 'shift_work') {
        return 'сменный график'
    }  else if (employmentSheduleString === 'remote') {
        return 'удаленная работа'
    } else if (employmentSheduleString === 'flexible') {
        return 'гибкий график'
    } 
}

export const getDateYear = (dateTimeString) => {
    if (dateTimeString === null) {
        return 'по н.в'
    }
    const date = new Date(dateTimeString);
    const year = date.getFullYear();

    return year
}

export const transliterate = (text) => {
    const russianToEnglish = {
        А: 'A', а: 'a',
        Б: 'B', б: 'b',
        В: 'V', в: 'v',
        Г: 'G', г: 'g',
        Д: 'D', д: 'd',
        Е: 'E', е: 'e',
        Ё: 'Yo', ё: 'yo',
        Ж: 'Zh', ж: 'zh',
        З: 'Z', з: 'z',
        И: 'I', и: 'i',
        Й: 'Y', й: 'y',
        К: 'K', к: 'k',
        Л: 'L', л: 'l',
        М: 'M', м: 'm',
        Н: 'N', н: 'n',
        О: 'O', о: 'o',
        П: 'P', п: 'p',
        Р: 'R', р: 'r',
        С: 'S', с: 's',
        Т: 'T', т: 't',
        У: 'U', у: 'u',
        Ф: 'F', ф: 'f',
        Х: 'Kh', х: 'kh',
        ц: 'ts', Ц: 'Ts',
        ч: 'ch', Ч: 'Ch',
        ш: 'sh', Ш: 'Sh',
        щ: 'sch', Щ: 'Sch',
        ы: 'y', Ы: 'Y',
        э: 'e', Э: 'E',
        ю: 'yu', Ю: 'Yu',
        я: 'ya', Я: 'Ya',
        ' ': ' ',
        '.': '.',
        ',': ',',
        '—': '-',
        '?': '?',
        '!': '!',
        ь: '`',
        ъ: '`',
        Ъ: '`',
        Ь: '`'
        // Добавьте другие символы по желанию
    };

    return text.split('').map(char => russianToEnglish[char] || char).join('');
}
