<template>
    <div class="!bg-[#F8F8F8] pb-[3.62rem] md:pb-[72px]">
        <div class="mb-[3.75rem] !bg-[#F8F8F8] md:mb-[80px]">
            <Header @toggleScroll="handleToggleScroll"></Header>
        </div>
        <div class="mb-[1.81rem] !bg-[#F8F8F8]">
            <div v-if="!authStore.isAuthenticated" class="pt-[1.31rem]  flex flex-row justify-center">
                <ResumeNoLoginCard :experienceUnits=resume.experience :imageSrcId=resume.avatar
                    :publicationTime=resume.created_at :employeeFirstName=resume.first_name
                    :employeeLastName=resume.last_name :citizenship=resume.citizenship_name
                    :specialityName=resume.speciality_name :city=resume.city_of_residence
                    :readyToMove=resume.ready_to_move :age=resume.full_years :price=resume.salary_expectations
                    :currencyName=resume.currency_display_symbol></ResumeNoLoginCard>
            </div>
            <div v-else class="pt-[1.31rem] md:pt-[100px]">
                <div class="flex flex-row justify-center md:h-[25px] md:mb-[20px]">


                    <router-link to="/employer/resumes"
                        class="flex flex-row justify-start items-center mb-[1rem] leading-[1.5rem] pl-[1.5rem] md:pl-0 cursor-pointer w-full md:w-[993px]">
                        <Icon name="chevron-left"></Icon>
                        <div class="text-[0.87rem] md:text-[22px] text-[#000000] font-[Inter] mt-[0.1rem]">Вернуться к
                            списку</div>
                    </router-link>
                </div>

                <ResumeFullCard :id=resumeId :imageSrcId=resume.avatar :publicationTime=resume.created_at
                    :employeeFirstName=resume.first_name :employeeLastName=resume.last_name :contacts="resume.contacts"
                    :citizenship=resume.citizenship_name :specialityName=resume.speciality_name
                    :employmentType=resume.employment_type :employmentShedule=resume.employment_schedule
                    :city=resume.city_of_residence :readyToMove=resume.ready_to_move :experience=resume.work_experience
                    :age=resume.full_years :price=resume.salary_expectations
                    :currencyName=resume.currency_display_symbol :experienceUnits=resume.experience
                    :about=resume.about_me :educationUnits=resume.education>
                </ResumeFullCard>
            </div>

        </div>
        <div class="flex flex-row justify-center">
            <div class="md:w-[1032px] w-[400px]">
                <div
                    class="ml-[1.5rem] text-[1.06rem] md:ml-[20px] md:mr-[20px] md:text-[22px] md:mb-[10px] text-[#000000] font-[Inter] font-semibold mb-[0.5rem] cursor-pointer">
                    Похожие резюме
                </div>

                <CardsSlider>
                    <div class="ml-5 md:ml-[20px]">
                    </div>
                    <div v-for="(item, index) in resumes" :key="index">
                        <router-link :to="{ path: `/employer/resumes/${item.id}` }">
                            <SimularResumeCard :publicationTime=item.created_at :vacancyName=item.speciality_name
                                :price=item.salary_expectations :currencyName=item.currency_display_symbol
                                :age=item.full_years :experience=item.work_experience v-model="isChecked3">
                            </SimularResumeCard>
                        </router-link>

                    </div>

                </CardsSlider>
            </div>
        </div>

    </div>
    <Footer></Footer>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import SimularResumeCard from './../components/cards/SimularResumeCard.vue'
import ResumeFullCard from './../components/cards/ResumeFullCard.vue'
import Header from './../components/ui/Header.vue'
import { ref, reactive, watch, onMounted, computed } from 'vue';
import CardsSlider from "@/components/ui/CardsSlider";
import Footer from './../components/ui/Footer.vue'
import ResumeNoLoginCard from './../components/cards/ResumeNoLoginCard.vue'
import Icon from './../components/ui/Icon.vue';
import { useFetch } from '@/composables/useFetch';

const authStore = useAuthStore();

const route = useRoute();
const resumeId = ref(route.params.id);

const { anonRequest, authRequest } = useFetch();

const resume = ref({});
const resumes = ref([]);
const loading = ref(false);
const error = ref(null);
const itemsPerPage = ref(5)
const page = ref(1)
const totalCount = ref(1)

const offset = computed(() => {
    return (page.value - 1) * itemsPerPage.value
})

const totalPages = computed(() => {
    return Math.floor(totalCount.value / itemsPerPage.value) + 1
})

onMounted(() => {
    fetchResumeData({});
    fetchResumesData()
});

const fetchResumeData = async () => {
    console.log('Fetching resume data...');
    loading.value = true; // Устанавливаем статус загрузки
    error.value = null; // Сбрасываем предыдущую ошибку

    try {
        let response
        if (authStore.isAuthenticated) {
            response = await authRequest(`/resumes/${resumeId.value}/`, {
                method: 'GET',
            });
        } else {
            response = await anonRequest(`/resumes/${resumeId.value}/`, {
                method: 'GET',
            });
        }


        // Если запрос успешен, сохраняем данные
        if (response && !response.error) {
            resume.value = response; // Предполагаем, что API возвращает массив данных
        } else {
            // Если есть ошибка, устанавливаем ошибку
            error.value = response;
        }
    } catch (err) {
        console.error('Запрос завершился ошибкой:', err);
        error.value = err; // Устанавливаем ошибку
    } finally {
        loading.value = false; // Сбрасываем статус загрузки
    }
};

const fetchResumesData = async (queryParams = {}) => {
    console.log('Fetching resumes data...');
    loading.value = true; // Устанавливаем статус загрузки
    error.value = null; // Сбрасываем предыдущую ошибку
    queryParams.limit = itemsPerPage.value
    queryParams.offset = offset.value

    try {
        // Создаем строку запроса вручную
        const queryArray = [];
        for (const key in queryParams) {
            const value = queryParams[key];
            // Проверьте, является ли значение массивом
            if (Array.isArray(value)) {
                // Если массив, объедините значения через запятую
                queryArray.push(`${key}=${value.join(',')}`);
            } else {
                queryArray.push(`${key}=${value}`);
            }
        }
        let queryString = queryArray.join('&');
        queryString
        const url = `/resumes/${queryString ? '?' + queryString : ''}`;



        const response = await anonRequest(url, {
            method: 'GET',
        });

        console.log('Request URL:', response); // Логируем конечный URL

        // Если запрос успешен, сохраняем данные
        if (response && !response.error) {
            resumes.value = response.results; // Предполагаем, что API возвращает массив данных
            totalCount.value = response.count
        } else {
            // Если есть ошибка, устанавливаем ошибку
            error.value = response;
        }
    } catch (err) {
        console.error('Запрос завершился ошибкой:', err);
        error.value = err; // Устанавливаем ошибку
    } finally {
        loading.value = false; // Сбрасываем статус загрузки
    }
};

const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});

watch(
    () => route.params.id,
    (newId) => {
        resumeId.value = newId;
        fetchResumeData();
        fetchResumesData();
    }
);

const isChecked3 = ref(false)

const showPopup = ref(false);

const togglePopup = () => {
    showPopup.value = !showPopup.value;
};

const showPopupSortTest = ref(false);

const togglePopupSortTest = () => {
    console.log('1')
    showPopupSortTest.value = !showPopupSortTest.value;
};

const showPopupFilterTest = ref(false);

const togglePopupFilterTest = () => {
    console.log('2')
    showPopupFilterTest.value = !showPopupFilterTest.value;
};

const tabs = [
    { name: "tab1", label: "Новые вакансии" },
    { name: "tab2", label: "Все вакансии" },
];

const activeTab = ref(0);

</script>

<style></style>