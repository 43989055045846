<template>
    <div class="mb-[3.75rem] !bg-[#F8F8F8] md:mb-[80px]">
        <Header @toggleScroll="handleToggleScroll"></Header>
    </div>
    <div class="!bg-[#F8F8F8] pb-[1.81rem] flex flex-row justify-center">
        <div class="flex flex-col md:w-[993px]">
            <div class="mr-[1.5rem] ml-[1.5rem] md:ml-0 md:mr-0">
                <div
                    class="mt-[1rem] md:mt-[70px] leading-[1.06rem] md:leading-[17px] max-w-[21.25rem] md:max-w-[700px] mb-[1.25rem] md:mb-[20px]">
                    <div v-if="!authStore.isAuthenticated"
                        class="text-[0.87rem] md:text-[16px] text-[#282828] font-[Inter] font-light">
                        <router-link to="/signup">
                            <span class="md:h-[20px] custom-underline cursor-pointer">Зарегистрируйтесь</span>
                        </router-link>
                        чтобы разблокировать больше возможностей
                    </div>
                </div>
                <ResumeSearch placeholder="Поиск по резюме" v-model="searchTerm" @search="handleSearch"></ResumeSearch>
            </div>

            <div class="mb-[1.81rem] ml-[1.5rem] md:ml-0 md:mb-0 !bg-[#F8F8F8] cursor-pointer md:h-[60px] md:mt-[10px]">
                <div class="pt-[1.31rem] md:pt-[15px]">
                    <router-link to="/employee/vacancies"
                        class="flex flex-row justify-start items-center mb-[1rem] leading-[1.5rem] md:leading-[27px] cursor-pointer md:w-[993px]">
                        <Icon name="chevron-left"></Icon>
                        <span class="text-[0.87rem] md:text-[18px] text-[#000000] font-[Inter] mt-[0.1rem]">Вернуться к
                            списку</span>
                    </router-link>
                </div>
            </div>
            <div class="mb-[1.25rem] mr-[1.5rem] ml-[1.5rem] md:ml-0 md:mr-0 md:mb-[16px]">
                <VacancyCardPartOpen :vacancyName=vacancy.name :priceFrom=vacancy.price_from :priceTo=vacancy.price_to
                    :experience=vacancy.experience :currencyName=vacancy.currency_display_name v-model="isChecked3"
                    :employmentType=vacancy.employment_type :employmentShedule=vacancy.employment_schedule>
                </VacancyCardPartOpen>
            </div>
            <div class="mb-[3.81rem] mr-[1.5rem] ml-[1.5rem] md:ml-0 md:mr-0 md:mb-[41px]">
                <CompanyCard :companyName=employer.company_name
                    :grade=employer.grade :reportsCount=employer.reports_count :imageSrcId=employer.avatar>
                </CompanyCard>
            </div>
            <div style="white-space: normal; overflow-wrap: anywhere;"
                class="md:w-[993px] h-full ml-[1.5rem] mr-[1.5rem] md:ml-0 md:mr-0 mb-[3.43rem] md:mb-[51px] text-[1rem] md:text-[18px] text-[#000000] font-[Inter] font-normal">
                {{ vacancy.full_description }}
            </div>
            <div class="flex flex-col md:flex-row ml-[1.5rem] mr-[1.5rem] md:mr-0 md:ml-0 md:mb-0">
                <Button v-if="authStore.me && authStore.me.role === 'employee'" type="send-welcome-1" class="mb-[0.43rem] md:mr-[24px] md:!h-[46px] md:w-[300px]"
                    @click="router.push(`/employee/do_response?vacancy_id=${vacancyId}`)">Откликнуться</Button>
                <div></div>
                <!--                <Button type="get-contacts" class="md:mr-0 md:ml-0 md:mb-0 md:!h-[46px]">Показать контакты</Button>-->
            </div>

        </div>



    </div>
    <Footer></Footer>
</template>

<script setup>
import ResumeSearch from './../components/cards/ResumeSearch.vue'
import VacancyCardPartOpen from './../components/cards/VacancyCardPartOpen.vue'
import CompanyCard from './../components/cards/CompanyCard.vue'
import Header from './../components/ui/Header.vue'
import { ref, reactive, watch, onMounted, onUnmounted } from 'vue';
import Footer from './../components/ui/Footer.vue'
import Icon from './../components/ui/Icon.vue';
import Button from './../components/ui/Button.vue';
import { useFetch } from '@/composables/useFetch';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
const authStore = useAuthStore();

const route = useRoute();
const router = useRouter();
const vacancyId = ref(route.params.id);

const { anonRequest, authRequest } = useFetch();

const vacancy = ref({});
const employer = ref({});
const loading = ref(false);
const error = ref(null);

const searchTerm = ref(null);

const handleSearch = () => {
    let routerLink = '/employee/vacancies'
    if (searchTerm.value) {
        routerLink += `?profession=${searchTerm.value}`
    }
    router.push(routerLink)
}

onMounted(() => {
    fetchVacancyData();
    console.log('vacancy')
});

const fetchVacancyData = async () => {
    console.log('Fetching vacancy data...');
    loading.value = true; // Устанавливаем статус загрузки
    error.value = null; // Сбрасываем предыдущую ошибку

    try {

        let response
        if (authStore.isAuthenticated) {
            response = await authRequest(`/vacancies/${vacancyId.value}/`, {
                method: 'GET',
            });
        } else {
            response = await anonRequest(`/vacancies/${vacancyId.value}/`, {
                method: 'GET',
            });
        }

        // Если запрос успешен, сохраняем данные
        if (response && !response.error) {
            vacancy.value = response; // Предполагаем, что API возвращает массив данных
            console.log('zfasdfsaf', response)
        } else {
            // Если есть ошибка, устанавливаем ошибку
            error.value = response;
        }
    } catch (err) {
        console.error('Запрос завершился ошибкой:', err);
        error.value = err; // Устанавливаем ошибку
    } finally {
        loading.value = false; // Сбрасываем статус загрузки
    }
};

watch(vacancy, (newValue) => {
    if (newValue.employer) {
        employer.value = newValue.employer
    }
});

const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});

const isChecked3 = ref(false)
const gradeCompany = ref(3.9)

const showPopup = ref(false);

const togglePopup = () => {
    showPopup.value = !showPopup.value;
};

const showPopupSortTest = ref(false);

const togglePopupSortTest = () => {
    console.log('1')
    showPopupSortTest.value = !showPopupSortTest.value;
};

const showPopupFilterTest = ref(false);

const togglePopupFilterTest = () => {
    console.log('2')
    showPopupFilterTest.value = !showPopupFilterTest.value;
};

const tabs = [
    { name: "tab1", label: "Новые вакансии" },
    { name: "tab2", label: "Все вакансии" },
];

const activeTab = ref(0);

</script>

<style></style>