import {defineStore} from 'pinia';
import { useRouter } from 'vue-router';
const router = useRouter();
import { useFetch } from '@/composables/useFetch';
const { anonRequest } = useFetch();
const { authRequest } = useFetch(router);

import {jwtDecode} from 'jwt-decode';

// const fetch = useFetch()

export const useBillingStore = defineStore('billingStore', {
  state: () => ({
    plans: { // тарифы
      items: [], // список тарифов
      current: {}
    },
    subscriptions: {
      items: [], // список подписок
      // current: {}
      count: 0
    }
  }),
  actions: {
    getPlans() {
      return anonRequest('/rates/').then((data) => {
        this.plans.items = data.results
      })

    },
    getPlan(id, router=null) {
      
      console.log('get_plan!!!')
      return authRequest(`/rates/${id}/`).then((data) => {
        this.plans.current = data
        
      })
    },
    getSubscriptions(offset=0, limit=5) {  
      try {
          return authRequest(`/subscriptions?offset=${offset}&limit=${limit}`).then((result) => {
              this.subscriptions.items = result.results
              this.subscriptions.count = result.count
              console.log('this subscriptions', this.subscriptions)
          });
      } catch (error) {
          console.error('Ошибка при загрузке подписок:', error);
          // Обработка ошибки (например, уведомление)
      }
  },

  },
});