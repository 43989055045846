<template>
  <Sheet v-if="isMobile" class="p-[1rem_1.37rem_0.75rem_1.37rem] cursor-pointer" @click="toVacancy">
    <slot name="date-time">
      <span v-if="noDate" class="font-inter font-normal text-[0.81rem] text-[#00BD35]">{{
        formatDateTime(publicationTime) }}
      </span>
    </slot>
    <slot name="mainInfo">
      <div class="flex justify-between mb-[0.37rem]">
        <span class="font-inter font-semibold text-[1.12rem] text-black">{{ vacancyName }}</span>
        <CheckboxStar v-model:checked="checkedStar"></CheckboxStar>
      </div>
      <div v-if="(priceFrom && priceTo) && (priceFrom !== priceTo)" class="font-inter font-normal text-black">От
        {{ formatPrice(priceFrom) }} {{ currencyName }} до {{ formatPrice(priceTo) }} {{ currencyName }}
      </div>
      <div v-else-if="(priceFrom && priceTo) && (priceFrom === priceTo)" class="font-inter font-normal text-black">
        {{ formatPrice(priceFrom) }} {{ currencyName }}
      </div>
      <div v-else-if="(priceFrom)" class="font-inter font-normal text-black">От {{ formatPrice(priceFrom) }}
        {{ currencyName }}
      </div>
      <div v-else-if="(priceTo)" class="font-inter font-normal text-black">От {{ formatPrice(priceTo) }} {{
    currencyName
  }}
      </div>
      <div v-else class="mb-[1rem]">
      </div>
      <div class="flex flex-row justify-between mb-[1.5rem] items-center">
        <span class="font-inter font-medium text-[1.12rem] text-[#00C8EF]">{{ city }}</span>
        <span v-if="!noDate" class="font-inter font-normal text-[0.81rem] text-[#00BD35]">{{
    formatDateTime(publicationTime) }}</span>
      </div>
    </slot>

    
    <slot name="actions"></slot>
    <div v-if="!disableResponseButton">
      <Button @click.stop="router.push(`/employee/do_response?vacancy_id=${vacancyId}`)" type="send-welcome-1">Откликнуться</Button>
    </div>
  </Sheet>
  <Sheet v-else class="p-[16px_20px_14px_28px] w-full cursor-pointer" @click="toVacancy">
    <div class="flex flex-col">
      <slot name="date-time">
        <div class="font-inter font-normal text-[12px] text-[#00BD35] mb-[16px]">
          {{ formatDateTimeVacancyCreated(publicationTime) }}
        </div>
      </slot>

      <slot name="mainInfo">
        <div class="flex flex-row mb-[5px]">
          <div class="font-inter font-bold text-[16px] text-[#2894FF]">{{ vacancyName }}</div>
          <div class="font-inter font-normal text-[14px] text-[#2894FF] mt-[2px] ml-[17px]">
            {{ formatexperienceForVacancy(experience) }}
          </div>
        </div>
        <div v-if="(priceFrom && priceTo) && (priceFrom !== priceTo)"
          class="font-inter text-[16px] font-bold mb-[12px]">
          От {{ formatPrice(priceFrom) }} {{ currencyName }} до {{ formatPrice(priceTo) }} {{ currencyName }}
        </div>
        <div v-else-if="(priceFrom && priceTo) && (priceFrom === priceTo)"
          class="font-inter text-[16px] font-bold mb-[12px]">{{ formatPrice(priceFrom) }} {{ currencyName }}
        </div>
        <div v-else-if="(priceFrom)" class="font-inter text-[16px] font-bold mb-[12px]">От {{ formatPrice(priceFrom) }}
          {{ currencyName }}
        </div>
        <div v-else-if="(priceTo)" class="font-inter text-[16px] font-bold mb-[12px]">От {{ formatPrice(priceTo) }}
          {{ currencyName }}
        </div>
        <div v-else class="mb-[28px]">
        </div>
        <div class="flex flex-row justify-between">
          <div class="flex flex-col">
            <div class="font-inter font-normal text-[16px]">{{ company }}</div>
            <div class="font-inter font-normal text-[16px]">{{ city }}</div>
          </div>


        </div>
      </slot>

      <div class="mt-3">
        <slot name="actions"></slot>
      </div>
      <div v-if="!disableResponseButton">
        <Button v-if="authStore.me && authStore.me.role === 'employee'" type="send-welcome-1" class="!h-[44px] !w-[210px]"
          @click.stop="router.push(`/employee/do_response?vacancy_id=${vacancyId}`)">Откликнуться</Button>
      </div>




    </div>
  </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Button from './../../components/ui/Button.vue';
import { defineProps, defineEmits, computed, ref, onMounted, onUnmounted } from 'vue';
import {
  formatPrice,
  formatDateTime,
  formatDateTimeVacancyCreated,
  formatexperienceForVacancy
} from '@/composables/utils';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useAuthStore} from '@/stores/authStore';


const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

const toVacancy = () => {
  router.push(`/employee/vacancies/${props.vacancyId}`)
};

const props = defineProps({
  vacancyId: {
    type: Number,
    default: null,
  },
  vacancyName: {
    type: String,
    default: 'Текст',
  },
  priceFrom: {
    type: Number,
    default: null,
  },
  priceTo: {
    type: Number,
    default: null,
  },
  currencyName: {
    type: String,
    default: null,
  },
  city: {
    type: String,
    default: 'Текст',
  },
  publicationTime: {
    type: String,
    default: 'Текст',
  },
  experience: {
    type: String,
    default: 'Текст',
  },
  company: {
    type: String,
    default: 'Текст',
  },
  disableResponseButton: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  noDate: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['update:modelValue']);
const checkedStar = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});
</script>

<style scoped></style>