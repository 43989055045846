<template>
  <div class="tariff-card px-[24px] py-[20px]" :class="{'active-card': props.active}">
    <div class="tariff-card-name">
      {{ name }}
    </div>

    <div class="tariff-card-description pb-[10px]">
      {{ shortDescription }}
    </div>

    <div class="tariff-card-price mt-[20px]">
      {{ price }} ₽
    </div>

    <div class="tariff-card-features mt-[20px] flex flex-col gap-[20px]">
      <div class="tariff-card-feature">
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 1L6 12L1 7" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div>{{ vacanciesForPublication }} Вакансий</div>
      </div>
      <div v-if="props.canSeeContacts" class="tariff-card-feature">
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 1L6 12L1 7" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div>Контакты соискателей доступны</div>
      </div>
      <div v-else class="tariff-card-feature">
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L17 12" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M1 12L17 1" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div>Контакты соискателей недоступны</div>
      </div>
      <div v-if="props.canSendResponses" class="tariff-card-feature">
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 1L6 12L1 7" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div>Можно приглашать соискателей</div>
      </div>
      <div v-else class="tariff-card-feature">
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L17 12" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M1 12L17 1" stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div>Нельзя приглашать соискателей</div>
      </div>


    </div>
    <div v-if="!onBueTarifPage">
      <div v-if="!active" class="mt-[30px]">
      <Button type="login-logincard" class="w-full" @click="router.push(`/tarifs/${id}`)">Купить</Button>
    </div>
    <!-- <div v-else class="mt-[30px]">
      <Button type="login-logincard" class="w-full" @click="router.push(`/tarifs/${id}`)">Купить</Button>
    </div> -->
    <div v-else class="mt-[30px] tariff-card-price">
      Активен до: {{ dateEnd }}
    </div>
    </div>
    
    

    <div class="mt-[30px]"></div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import Button from "@/components/ui/Button";
import {useAuthStore} from "@/stores/authStore";

const authStore = useAuthStore()

const router = useRouter();
const route = useRoute();

// const publish = (id) => {}

const onBueTarifPage = computed(() => {
  const regex = /^\/tarifs\/\d+$/;
  return regex.test(route.path);
})

const props = defineProps({
  id: {
    default: null
  },
  name: {
    default: 'Тариф'
  },
  shortDescription: {
    default: null
  },
  vacanciesForPublication: {
    type: Number,
    default: 10
  },
  canSeeContacts: {
    type: Boolean,
    default: false
  },
  canSendResponses: {
    type: Boolean,
    default: false
  },
  price: {
    default: 100
  },
  active: {
    default: false
  },
  dateEnd: {
    default: null
  }
  // pricePerMonth: {
  //   default: 100
  // },
  // pricePer3Month: {
  //   default: 100
  // },
  // pricePer6Month: {
  //   default: 100
  // },
  // pricePerYear: {
  //   default: 100
  // },

})

// const emit = defineEmits([
//   'update:modelValue'
// ])
//
// const clickHandler = () => {
//   emit('update:modelValue', !props.modelValue)
// }
</script>

<style scoped>
.tariff-card {
  background: #FFFFFF;
  border: 2px solid #ECECEC;
  border-radius: 15px;
  cursor: pointer;
  width: full;

}

.tariff-card[active="true"] {
  border: 2px solid #2894FF;

}

.active-card {
  background-color: #e0f7e0; /* Зеленый фон */
  border: 2px solid #4caf50; /* Более жирный зеленый контур */
}

.tariff-card:hover {
  border: 2px solid #76b7f8;

}

.tariff-card-name {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #2894FF;


}

.tariff-card-description {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  leading-trim: both;
  text-edge: cap;
  letter-spacing: 0.04em;

  color: #888888;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;


  box-sizing: border-box;


  border-bottom: 1px solid #ECECEC;


}


.tariff-card-price {
  /* 4 444 ₽ */


  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #4A4A4A;


}

.tariff-card-features {}

.tariff-card-feature {
  /* Преимущество 2 */

  display: flex;
  flex-direction: row;
  gap: 18px;
  /* Добавлено свойство gap */
  align-items: center;
  /* Добавлено свойство align-items */
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* идентична высоте блока */

  color: #000000;
}
</style>