<template>
    <Sheet class="!p-[0.93rem_0.81rem_1.28rem_1.12rem] md:!p-[35px_61px_25px_61px] md:!rounded-[20px]">
        <div class="flex justify-between leading-[1.18rem] md:leading-[27px]">
            <span class="font-inter font-bold text-[1rem] md:text-[26px] text-black">{{ vacancyName }}</span>
            <CheckboxStar v-model:checked="checkedStar"></CheckboxStar>
        </div>
        <div class="mb-[1rem] md:mb-[20px]">
            <span v-if="(priceFrom && priceTo) && (priceFrom !== priceTo)"
                class="font-inter font-normal text-[0.87rem] md:text-[16px] leading-[1.06rem] md:leading-[17px] text-black">От
                {{ formatPrice(priceFrom) }} {{ currencyName }} до
                {{ formatPrice(priceTo) }} {{ currencyName }}</span>
            <span v-else-if="(priceFrom && priceTo) && (priceFrom === priceTo)"
                class="font-inter font-normal text-[0.87rem] md:text-[16px] leading-[1.06rem] md:leading-[17px] text-black">{{
                formatPrice(priceFrom) }} {{ currencyName }}</span>
            <span v-else-if="(priceFrom)"
                class="font-inter font-normal text-[0.87rem] md:text-[16px] leading-[1.06rem] md:leading-[17px] text-black">От
                {{ formatPrice(priceFrom)
                }} {{ currencyName }}</span>
            <span v-else-if="(priceTo)"
                class="font-inter font-normal text-[0.87rem] md:text-[16px] leading-[1.06rem] md:leading-[17px] text-black">От
                {{ formatPrice(priceTo) }}
                {{ currencyName }}</span>
        </div>
        <div class="flex justify-start leading-[1.06rem] md:leading-[17px]">
            <span
                class="font-inter font-normal md:font-medium text-[0.87rem] md:text-[16px] text-black mr-[0.1rem]">Требуемый
                опыт работы:</span>
            <span class="font-inter font-medium text-[0.87rem] md:text-[16px] text-black">{{  formatexperienceForVacancy(experience) }}</span>
        </div>
        <div class="flex justify-start mb-[1.18rem] md:mb-[25px]">
            <span
                class="font-inter font-normal md:font-medium text-[0.87rem] md:text-[16px] leading-[1.25rem] md:leading-[20px] text-black mr-[0.1rem]">
                {{ humanizeEmploymentType(employmentType) }}, {{ humanizeEmploymentShedule(employmentShedule) }}
            </span>
        </div>
        <div class="flex flex-col md:flex-row w-full">
            <div class="mb-[0.43rem] md:mb-0 w-full md:mr-[24px]">
                <Button v-if="authStore.me && authStore.me.role === 'employee'" type="send-welcome-1" class="md:!h-[46px] md:w-full" @click="router.push(`/employee/do_response?vacancy_id=${vacancyId}`)">Откликнуться</Button>
            </div>
            <div class="w-full">
<!--                <Button type="get-contacts" class="md:!h-[46px] md:w-full">Показать контакты</Button>-->
            </div>
        </div>

    </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Button from './../../components/ui/Button.vue';
import { defineProps, defineEmits, computed, ref } from 'vue';
import { formatPrice, formatDateTime, formatDateTimeVacancyCreated, formatexperienceForVacancy, humanizeEmploymentShedule, humanizeEmploymentType } from '@/composables/utils';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();
const vacancyId = ref(route.params.id);
import { useAuthStore} from '@/stores/authStore';


const authStore = useAuthStore();

const props = defineProps({
    vacancyName: {
        type: String,
        default: 'Текст',
    },
    priceFrom: {
        type: Number,
        default: null,
    },
    priceTo: {
        type: Number,
        default: null,
    },
    currencyName: {
        type: String,
        default: null,
    },
    experience: {
        type: String,
        default: 'Текст',
    },
    employmentType: {
        type: String,
        default: 'Текст',
    },
    employmentShedule: {
        type: String,
        default: 'Текст',
    },
    modelValue: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue']);
const checkedStar = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});
</script>

<style scoped></style>