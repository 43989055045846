<template>
  <LKLayout >
    <div class="wj-container w-full md:w-[645px] md:min-h-[300px]   px-[20px] md:px-[60px] py-[20px] md:py-[52px]">
      <!--    Заголовок    -->
      <div class="flex flex-col justify-between mb-[60px]">
        <div class="title whitespace-nowrap !text-left md:mb-5 !text-[22px] md:!text-[28px]">
          {{mode === 'create' ? 'Создание вакансии' : 'Редактирование вакансии'}}
        </div>
        <div class="flex flex-col md:flex-row md:gap-[50px]">
          <div class="nav-item" :active="stage === 0 ? true : undefined" @click="stage = 0">Основная информация</div>
          <div class="nav-item" :active="stage === 1 ? true : undefined" @click="stage = 1">Контакты</div>
<!--          <div class="nav-item" :active="stage === 2 ? true : undefined" @click="stage = 2">Тип публикации</div>-->
        </div>
      </div>


      <div class="md:w-full">
        <template v-if="stage === 0">
          <!--    Название, специализация, город для поиска    -->
          <div>
            <label for="form-name" class="form-label">Название вакансии</label>
            <UIInput v-model="form.name" id="form-name" :rules="[isNotEmpty]"
              @valid="isValid => updateValidity('name', isValid)"></UIInput>
          </div>

          <div class="mt-[50px]">
            <label for="form-name" class="form-label">Специализация</label>
            <div class="form-sublabel">На какую работу ищете кандидата?</div>
            <UIInput v-model="form.specialization" id="form-name" :rules="[isNotEmpty]"
              @valid="isValid => updateValidity('specialization', isValid)"></UIInput>
          </div>

          <div class="mt-[50px]">
            <label for="form-name" class="form-label">Город для поиска сотрудника</label>
            <div class="form-sublabel">Укажите город, в котором хотите искать сотрудника (1 город для одной
              публикации).
            </div>
            <UISelect v-model="form.city" :options="cities" :rules="[isNotEmpty]"
              @valid="isValid => updateValidity('city', isValid)" class="w-full"></UISelect>
          </div>

          <!--     Заработная плата     -->

          <div class="mt-[50px]">
            <label for="form-name" class="form-label">Предполагаемый уровень заработной платы</label>
            <div class="form-sublabel">Укажите уровень дохода в месяц или за объем работ, данная информация повышает
              процент откликов.
            </div>
            <div class="flex flex-col md:flex-row gap-[25px]">
              <input v-model="form.price_from" id="form-name" class="form-input w-[150px]" placeholder="От" />
              <input v-model="form.price_to" id="form-name" class="form-input w-[150px]" placeholder="До" />
              <UISelect :model-value="'rub'" class=""
                        triggerClass="w-full  !w-[150px]"
                :options="[{ title: '$ (Доллар)', value: 'usd' }, { title: '€ (Евро)', value: 'euro' }, { title: '₽ (Российский рубль)', value: 'rub' }]">
              </UISelect>
            </div>


          </div>
          <!--     Опыт работы     -->

          <div class="mt-[50px]">
            <label for="form-name" class="form-label">Опыт работы</label>
            <!--            <div class="form-sublabel">Укажите уровень дохода в месяц или за объем работ, данная информация повышает-->
            <!--              процент откликов.-->
            <!--            </div>-->
            <div class="flex flex-col md:flex-row md:gap-[50px]">
              <!--             <UIRadioButtonGroup :options="[{label: 'Нет опыта', value: '123'},{label: 'От 3 мес до 1 года', value: '456'},{label: 'От 1 до 3 лет', value: '789'},]">-->

              <!--             </UIRadioButtonGroup>-->
              <div>
                <UIRadioButton v-model="form.experience" name="work-expirience" label="Нет опыта" value="no_experience">
                </UIRadioButton>
                <UIRadioButton v-model="form.experience" name="work-expirience" label="От 3 мес до 1 года"
                  value="months_3_12"></UIRadioButton>
                <UIRadioButton v-model="form.experience" name="work-expirience" label="От 1 до 3 лет" value="years_1_3">
                </UIRadioButton>
              </div>

              <div>
                <UIRadioButton v-model="form.experience" name="work-expirience" label="От 3 до 6 лет" value="years_3_6">
                </UIRadioButton>
                <UIRadioButton v-model="form.experience" name="work-expirience" label="От 6 лет и более"
                  value="years_more_6"></UIRadioButton>
              </div>


            </div>

          </div>


          <!--    Описание вакансии    -->
          <div class="mt-[50px]">
            <label for="form-name" class="form-label"> Описание вакансии</label>
            <div class="form-sublabel">Расскажите про вакансию. Не менее 150 символов. Опишите должностные обязанности
              и
              требования к кандидату.
            </div>
            <!-- <UIInput v-model="form.specialization" id="form-name"  ></UIInput> -->
            <UITextArea v-model="form.full_description" :rules="[isNotEmpty, less150Char]"
              @valid="isValid => updateValidity('full_description', isValid)" class="max-h-[300px] min-h-[100px]">
            </UITextArea>
          </div>

          <!--   Ключевые навыки    -->
          <div class="mt-[50px]">
            <label for="form-name" class="form-label">Ключевые навыки</label>
            <div class="form-sublabel">Например, владение разными методиками измерений.</div>
            <UITextArea v-model="form.key_skills" :rules="[isNotEmpty]"
              @valid="isValid => updateValidity('key_skills', isValid)" class="max-h-[300px] min-h-[100px]">
            </UITextArea>
          </div>

          <!--   Ключевые навыки    -->
          <div class="mt-[50px]">
            <label for="form-name" class="form-label"> Тип занятости</label>
            <div>
              <UIRadioButton v-model="form.employment_type" name="work-type" label="Полная занятость" value="full_day">
              </UIRadioButton>
              <UIRadioButton v-model="form.employment_type" name="work-type" label="Частичная занятость"
                value="not_full_day"></UIRadioButton>
              <UIRadioButton v-model="form.employment_type" name="work-type" label="Проектная работа" value="project">
              </UIRadioButton>
            </div>

            <div>
              <UIRadioButton v-model="form.employment_type" name="work-type" label="Стажировка" value="internship">
              </UIRadioButton>
              <UIRadioButton v-model="form.employment_type" name="work-type" label="Волонтерство" value="volunteering">
              </UIRadioButton>
            </div>
          </div>

          <div class="mt-[50px] flex flex-col md:flex-row gap-[25px]">
            <Button type="signup" class="md:w-[362px] !text-[14px] !h-[38px] whitespace-nowrap" @click="createOrSave('DRAFT')">Сохранить как черновик</Button>
            <Button type="login-logincard" class="md:w-[362px] !h-[38px]" @click="stage = 1">Продолжить</Button>
          </div>
        </template>


        <template v-if="stage === 1">
<!--          <div>-->
<!--            <label for="form-name" class="form-label">Менеджер вакансии </label>-->
<!--            <input id="form-name" class="form-input w-full" disabled />-->
<!--          </div>-->

<!--          <div class="mt-[50px]">-->
<!--            <label for="form-name" class="form-label">Контактные данные менеджера </label>-->
<!--            <div class="form-sublabel">Укажите номер телефона</div>-->
<!--            <input id="form-name" class="form-input w-full" placeholder="+7" disabled />-->
<!--            <div class="form-sublabel mt-[20px]">Укажите адрес электронной почты</div>-->
<!--            <input id="form-name" class="form-input w-full" placeholder="example@mail.ru" disabled />-->
<!--          </div>-->

<!--          <div class="mt-[50px] flex flex-col gap-[20px] md:gap-0">-->
<!--            <div class="flex-grow ">-->
<!--              <label class="form-label">Контактная информация</label>-->
<!--              <UIRadioButton v-model="form.show_contacts" name="resume-contact-info" label="Показывать в вакансии"-->
<!--                :value="true"></UIRadioButton>-->
<!--              <UIRadioButton v-model="form.show_contacts" name="resume-contact-info" label="Не показывать в вакансии"-->
<!--                :value="false"></UIRadioButton>-->
<!--            </div>-->
<!--            <div class="flex-grow md:mt-10">-->
<!--              <label class="form-label">Чат с соискателем</label>-->
<!--              <UIRadioButton v-model="form.chat_available" name="resume-chat" label="Включен, возможна переписка"-->
<!--                :value="true"></UIRadioButton>-->
<!--              <UIRadioButton v-model="form.chat_available" name="resume-chat" label="Выклолючен, переписка невозможна"-->
<!--                :value="false"></UIRadioButton>-->
<!--            </div>-->

<!--          </div>-->

          <div class="mt-[50px] ">
            <label class="form-label">Кто может откликаться</label>

            <div class="flex flex-col gap-[20px]">
              <Checkbox v-model="form.can_respond_no_resume" large="true" title="Соискатели без резюме"></Checkbox>
              <Checkbox v-model="form.can_respond_incomplete_resume" large="true" title="Соискатели с неполным резюме">
              </Checkbox>
              <Checkbox v-model="form.can_respond_cover_letter" large="true" title="Только с сопроводительным письмом">
              </Checkbox>
            </div>


          </div>

          <div class="mt-[50px] flex flex-col md:flex-row gap-[10px] md:gap-[25px]">
            <Button type="signup" class="md:w-[362px] !text-[14px] !h-[38px] whitespace-nowrap" @click="createOrSave('DRAFT')">Сохранить как
              черновик</Button>
            <Button v-if="allTrue" type="login-logincard" class="md:w-[362px] !h-[38px] whitespace-nowrap" @click="createOrSave('PUBLISHED')">{{
                savePublishedButtonLabel }}</Button>
            <div v-else class="error_message">Публикация недоступна для незаполеннной формы</div>
          </div>

<!--          <div class="mt-[50px] flex flex-col md:flex-row gap-[25px]">-->
<!--            <Button type="signup" class="md:w-[362px] !text-[14px] whitespace-nowrap">Сохранить как черновик</Button>-->
<!--            <Button type="login-logincard" class="md:w-[362px]" @click="stage = 2">Продолжить</Button>-->
<!--          </div>-->


        </template>


<!--        <template v-if="stage === 2">-->
<!--          &lt;!&ndash;            {{tariffs}}&ndash;&gt;-->

<!--          <div class="md:w-full ">-->
<!--            <label for="form-name" class="form-label">Тип публикации</label>-->


<!--            <div class="flex flex-col md:flex-col  gap-[20px] w-full overflow-auto">-->
<!--              <TarifCard v-for="tariff in tariffs" :key="tariff.id" :name="tariff.name"-->
<!--                :short-description="tariff.short_description" :price="tariff.price"-->
<!--                :features="tariff.advantages.split('\n')" class="!w-full "-->
<!--                :model-value="form.tariffId === tariff.id" @click="form.tariffId = tariff.id"></TarifCard>-->
<!--            </div>-->
<!--          </div>-->


<!--          <div class="mt-[50px] flex gap-[25px]">-->
<!--            <Button type="signup" class="md:w-[362px] !text-[14px]" @click="createOrSave('DRAFT')">Сохранить как-->
<!--              черновик</Button>-->
<!--            <Button v-if="allTrue" type="login-logincard" class="md:w-[362px]" @click="createOrSave('PUBLISHED')">{{-->
<!--    savePublishedButtonLabel }}</Button>-->
<!--            <div v-else class="error_message">Публикация недоступна для незаполеннной формы</div>-->
<!--          </div>-->


<!--        </template>-->




      </div>


    </div>

  </LKLayout>
</template>

<script setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import LKLayout from "@/layouts/LKLayout";
import UISelect from "@/components/ui/UISelect";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UIRadioButtonGroup from "@/components/ui/UIRadioButtonGroup";
import UITextArea from "@/components/ui/UITextArea";
import UIInput from "@/components/ui/UIInput";
import { onMounted, reactive, ref, computed, watch } from "vue";
import Button from "@/components/ui/Button";
import Checkbox from "@/components/ui/Checkbox";
import TarifCard from "@/components/cards/TarifCard";
import { useFetch } from "@/composables/useFetch";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import {useToast} from "vue-toast-notification";
import {useAuthStore} from "@/stores/authStore";

const authStore = useAuthStore()

const toast = useToast()

const route = useRoute();
const router = useRouter();

const vacancyId = ref(route.params.id);
const fetched = ref(false)

const mode = computed(() => {
  if (vacancyId.value) {
    return 'update'
  } else {
    return 'create'
  }
});

const savePublishedButtonLabel = computed(() => {
  if (mode.value === 'update') {
    return 'Cохранить как опубликованное'
  } else {
    return 'Опубликовать'
  }
});

const fetch = useFetch()


const stage = ref(0)


const tariffs = ref([])
const cities = ref([])

const form = reactive({
  name: null,
  specialization: null,
  city: null,
  price_from: null,
  price_to: null,
  experience: 'no_experience',
  full_description: null,
  key_skills: null,
  employment_type: 'full_day',

  show_contacts: false,
  chat_available: false,
  can_respond_no_resume: true,
  can_respond_incomplete_resume: true,
  can_respond_cover_letter: true,

  tariffId: null,
})

const inputs = ref({
  'name': false,
  'specialization': false,
  'city': false,
  'full_description': false,
  'key_skills': false
});

const updateValidity = (index, isValid) => {
  inputs.value[index] = isValid;
  console.log('inputs.value', inputs.value)
}

const allTrue = computed(() => {
  return Object.values(inputs.value).every(value => value === true);
});

const isNotEmpty = (string) => {
  if (string === null) {
    return 'Поле не должно быть пустым'
  }
  if (string.length > 0) {
    return null
  } else {
    return 'Поле не должно быть пустым'
  }
}

const less150Char = (string) => {
  if (string === null) {
    return 'Поле не может быть менее 150 символов.'
  }
  if (string.length > 150) {
    return null
  } else {
    return 'Поле не может быть менее 150 символов.'
  }
}

onMounted(() => {

  const ratesRequest = fetch.anonRequest('/rates/').then((data) => {
    tariffs.value = data.results
  })
  const citiesRequest = fetch.anonRequest('/cities/').then((data) => {
    cities.value = data.results.map(item => ({
      title: item,
      value: item,
    }))
  })

  let vacancyRequest;

  if (mode.value === 'update') {
    vacancyRequest = fetch.authRequest(`/vacancies/${vacancyId.value}/`).then((data) => {
      form.name = data.name
      form.specialization = data.specialization
      form.city = data.city
      form.price_from = data.price_from
      form.price_to = data.price_to
      form.experience = data.experience
      form.full_description = data.full_description
      form.key_skills = data.key_skills
      form.employment_type = data.employment_type
      form.show_contacts = data.show_contacts
      form.chat_available = data.chat_available
      form.can_respond_no_resume = data.can_respond_no_resume
      form.can_respond_incomplete_resume = data.can_respond_incomplete_resume
      form.can_respond_cover_letter = data.can_respond_cover_letter
      form.tariffId = data.rate
      form.status = data.status
    })
  }

  Promise.all([citiesRequest, ratesRequest, vacancyRequest].filter(Boolean)).then(() => {

    fetched.value = true;
    console.log('vacancyRequest', form)
  });
})

const create = () => {
  console.log('form', form)
  // fetch.authRequest('/vacancies/', {
  //   method: 'POST',
  //   body: {
  //     ...form
  //   }
  // }).then(response => {
  //   // console.log('data', response)
  //   router.push('/employee/vacancies')
  // })
}

const createOrSave = (type) => {
  
  console.log(mode.value)
  if (type === 'PUBLISHED') {
    if (!authStore.me.user_subscription) {
      toast.error('Исчерапаны лимиты на публикацию или тариф не подключен. Купите тариф.',
                    {duration: 6000}
                )
      router.push('/employer/lk/tarif')
      return
    }
    if (form.status !== 'PUBLISHED') {
      if (authStore.me.vacancies_count >= authStore.me.user_subscription.vacancies_count) {
        toast.error('Исчерапаны лимиты на публикацию или тариф не подключен. Купите тариф.',
                    {duration: 6000}
                )
        router.push('/employer/lk/tarif')
        return
      }
      
    }
  }
  form.status = type
  if (mode.value === 'update') {
    fetch.authRequest(`/vacancies/${vacancyId.value}/`, {
      method: 'PUT',
      body: {
        ...form
      }
    }).then(response => {
      toast.success('Сохранено')
      console.log('udpated')
      authStore.getMe(true)
      router.push('/employer/lk/vacancies')
    })
  } else {
    fetch.authRequest('/vacancies/', {
      method: 'POST',
      body: {
        ...form
      }
    }).then(response => {
      console.log('created')
      toast.success('Вакансия создана')
      authStore.getMe(true)
      router.push('/employer/lk/vacancies')
      
    })
  }

}


</script>

<style scoped>
.wj-container {
  /* Rectangle 1001541 */


  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.title {
  /* Создание вакансии */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #4A4A4A;


}



.form-input {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;

  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 15px;

  height: 42px;


}

.form-label {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4A4A4A;
  margin-bottom: 20px;
}


.form-sublabel {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  leading-trim: both;
  text-edge: cap;
  letter-spacing: 0.04em;

  color: #888888;

  margin-bottom: 20px;

}

.error_message {

  font-family: 'Inter';
  font-style: normal;
  font-size: 14px;
  /* margin-bottom: 0px;
  line-height: 22px;
  letter-spacing: 0.04em; */
  margin-top: 0;
  color: #DB4545;
}
</style>