<template>
    <DefaultLayout>
        <div class="wj-container p-5 md:p-5 ">
            <div class="flex flex-row justify-center w-full">
                <div class="flex flex-col items-center w-full md:w-[992px]">
                    <div class="w-full md:w-[500px]">
                        <TarifCard :name="form.name" :price="priceFixed"
                            :vacanciesForPublication="form.vacanciesForPublication"
                            :canSeeContacts="form.canSeeContacts" :canSendResponses="form.canSendResponses"></TarifCard>
                        <div class="flex flex-col justify-start">
                            <div class="mt-[20px]">
                                <label for="form-name" class="form-label">Продолжительность</label>
                                <UISelect v-model="currentPeriod" :options="periods"
                                    @update:modelValue="discountPercent = 0; activePromo = null" class="w-full"></UISelect>
                            </div>
                            <div class="mt-[20px]">
                                <label for="form-name" class="form-label">Промокод</label>
                                <div class="flex flex-col md:flex-row w-full jsutify-between">
                                    <div class="w-full mb-5 md:mr-5 md:mb-0">
                                        <UIInput v-model="promo"></UIInput>
                                    </div>
                                    <Button type="login-logincard" @click="activatePromo">Активировать</Button>
                                </div>
                            </div>
                            <div class="mt-[20px]">
                                <label for="form-name" class="form-label">Итоговая цена</label>
                                <div class="flex md:flex-row">
                                    <div class="form-label mr-5 w-[500px]" style="font-size: 25px; line-height: 30px">{{
                                        priceDiscount }} ₽</div>
                                    <Button type="disabled" :disabled="true" @click="activatePromo">Оплатить</Button>
                                </div>
                                <div v-if="priceDiscount === 0" class="mt-[50px]">
                                    <Button type="login-logincard" @click="activateTarif">Активировать тариф</Button>
                                </div>
                                <div v-else class="mt-[50px]">
                                    <Button type="disabled" :disabled="true">Активировать тариф</Button>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>




    </DefaultLayout>

</template>

<script setup>
import TarifCard from "@/components/cards/TarifCard";
import UIInput from "@/components/ui/UIInput";
import { useBillingStore } from "@/stores/billingStore";
import { useRoute } from "vue-router";
import { ref, reactive, watch, onMounted, onUnmounted, onBeforeMount, computed } from 'vue';
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import UISelect from "@/components/ui/UISelect";
import Button from "@/components/ui/Button";
import { useFetch } from '@/composables/useFetch';
import { useToast } from "vue-toast-notification";
import { useAuthStore } from "@/stores/authStore";

const authStore = useAuthStore()
import router from "@/router";

const toast = useToast();

const fetch = useFetch();

const billingStore = useBillingStore()
const periods = ref([
    {
        title: 'Месяц',
        value: 'month'
    },
    {
        title: '3 месяца',
        value: 'months_3'
    },
    {
        title: '6 месяцев',
        value: 'months_6'
    },
    {
        title: 'Год',
        value: 'year'
    },
])
const currentPeriod = ref('month')
const promo = ref('')
const activePromo = ref(null)
const discountPercent = ref(0)
// const price = ref(null)

const route = useRoute();
const form = reactive({
    name: null,
    vacanciesForPublication: null,
    canSeeContacts: null,
    canSendResponses: null,
    pricePerMonth: null,
    pricePer3Month: null,
    pricePer6Month: null,
    pricePerYear: null,
})


const activatePromo = async () => {
    if (promo.value.length === 0) {
        toast.error('Промокод не должен быть пустым',
            { duration: 7000 } // dismissible: true // Уведомление можно закрыть только пользователем
        )
        return
    }
        
    await fetch.authRequest(`/promo/validate`, {
        method: 'POST',
        body: {
            code: promo.value,
            period: currentPeriod.value,
            rate: route.params.id,
        }
    }).then((data) => {
        console.log('data', data)
        if (data.status === 400) {
            toast.error(data.data,
                { duration: 7000 } // dismissible: true // Уведомление можно закрыть только пользователем
            )
        }
        else if (data.status === 422) {
            console.log(data)
            toast.error('Ошибка валидации',
                { duration: 7000 } // dismissible: true // Уведомление можно закрыть только пользователем
            )
        } else {
            toast.success('Промокод активирован!')
            discountPercent.value = data.percent
            activePromo.value = promo.value
        }
    })
}

const activateTarif = async () => {
    const errorStatuses = [400, 422, 500];
    await fetch.authRequest(`/subscription/activate`, {
        method: 'POST',
        body: {
            code: activePromo.value,
            rate: route.params.id,
        }
    }).then((data) => {
        console.log('data', data)
        if (errorStatuses.includes(data.status)) {
            toast.error(data.data,
                { duration: 7000 } // dismissible: true // Уведомление можно закрыть только пользователем
            )
        } else {
            toast.success('Тариф активирован!')
            router.push('/employer')
        }
        authStore.getMe(true)
    })
}

const priceFixed = computed(() => {
    if (currentPeriod.value === 'month') {
        return form.pricePerMonth
    } else if (currentPeriod.value === 'months_3') {
        return form.pricePer3Month
    } else if (currentPeriod.value === 'months_6') {
        return form.pricePer6Month
    } else if (currentPeriod.value === 'year') {
        return form.pricePerYear
    }
    else {
        return 0
    }
})

const priceDiscount = computed(() => {
    return priceFixed.value - (discountPercent.value * (priceFixed.value / 100))
})

// onBeforeMount(() => {

// })

onMounted(() => {
    billingStore.getPlan(route.params.id, router).then(() => {
        const item = billingStore.plans.current
        form.name = item.name,
            form.vacanciesForPublication = item.vacancies_for_publication,
            form.canSeeContacts = item.can_see_contacts,
            form.canSendResponses = item.can_send_responses,
            form.pricePerMonth = item.price_per_month,
            form.pricePer3Month = item.price_per_3_month,
            form.pricePer6Month = item.price_per_6_month,
            form.pricePerYear = item.price_per_year
    })
});

</script>

<style scoped>
.wj-container {
    /* Rectangle 1001541 */


    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.form-input {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 26px;
    gap: 10px;

    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 15px;

    height: 42px;


}


.form-label {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #4A4A4A;
    margin-bottom: 20px;
}


.form-sublabel {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    leading-trim: both;
    text-edge: cap;
    letter-spacing: 0.04em;

    color: #888888;

    margin-bottom: 20px;


}
</style>